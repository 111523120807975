import axios from 'axios';
import papi from '@/api/papi';
import auth from '@/api/auth';
import { set } from "@/utils/vuex";
import _ from 'lodash';

const state = () => ({
	schema: null,
	asset: null,
	form: null,
	cacheTime: null
});


const getters = {

	loaded(state)
	{
		const EXPIRE = 7200000; //two hours
		return state.cacheTime && state.cacheTime + EXPIRE > Date.now();
	},
	
};

const mutations = {
	
	setSchema: set('schema'),
	setAsset: set('asset'),
	setForm: set('form'),
	setLoaded(state, value)
	{
		if(value)
		{
			state.cacheTime = Date.now();
		} else {
			state.cacheTime = null;
		}
	}
};

const actions = {

	load: async ({commit}, form) => {
	
		const asset = _.find(form.assets, [ 'type', 'Form'])
		if(!asset)
		{
			throw new Error('Post does not include form');
		}

		const jwt = await auth.getCredentials();

		if(!jwt)
		{
			//redirect will happen, just exit
			return;
		}

		const response = await axios.get(asset.url, { headers: {Authorization: jwt}});

		try {
			commit('setSchema', JSON.parse(response.data.schema));
			commit('setAsset', asset);
			commit('setForm', response.data);
			commit('setLoaded', true);
		} catch(e) {
			console.error(e)
		}
		
	},

	submit: async ({state}, values) => {
		const id = state.form.id;
		
		return papi.submitForm(id, values)
	}

};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};