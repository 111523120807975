
import { set } from "@/utils/vuex";
import search from "@/api/search";
import _ from 'lodash';
import router from '@/router/index';

const state = () => ({
	text: null,
	results: [],
	priority: null,
	meta: {},
	loaded: false
});


const getters = {

	groups(state)
	{
		const keys = _.keys(state.results)
		if(state.priority)
		{
			const others = _.reject(keys, (key) => {
				return key === state.priority;
			})
			return [ state.priority, ...others ];
		} else {
			return keys;
		}
		
	},
	counts(state)
	{
		let counts = {
			categories: {},
			total: 0
		}
		_.each(state.results, (result, i) => {
			counts.total += result.length;
			counts.categories[i] = result.length;
		});

		return counts;
	},
	isEmpty(state, getters)
	{
		return getters.counts.total === 0;
	},
	isLoaded(state)
	{
		return state.loaded
	}
};

const mutations = {
	
	setSchema: set('results'),
	setText: set('text'),
	setPriority: set('priority'),
	setResults: set('results'),
	setMeta: set('meta'),
	setLoaded: set('loaded')
};

const actions = {

	submit: async ({commit}, { text }) => {
		
		commit('setText', text);

		const { results, meta } = await search.query(text);

		commit('setMeta', meta);
		commit('setResults', results);

		const path = router.currentRoute.path;
		
		_.each(meta, (category, key) => {
			if(path.indexOf(category.path) > -1)
			{
				commit('setPriority', key);
				return false;
			}
		});

		commit('setLoaded', true);

	},

	clear: async ({commit}) => {
		
		commit('setText', null);
		commit('setMeta', {});
		commit('setResults', []);
		commit('setLoaded', false);

	}

};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};