/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */

import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/lib/services/goto'

import store from '@/store/index';
import { strings } from '@/plugins/strings';
import _ from 'lodash';

Vue.use(VueRouter)

const routes = []

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior: (to, from, savedPosition) => {
		let scrollTo = 0

		if (to.hash) {
			scrollTo = to.hash
		} else if (savedPosition) {
			scrollTo = savedPosition.y
		}

		return goTo(scrollTo, { duration: 0 });
	}
});

router.initialize = () => {
	buildRoutes(store.getters['navigation/routes']);

	//add fallback after routes have been initialized
	router.addRoute(
		{
			path: '/',
			redirect: () => {
				return store.getters['navigation/home'];
			}
		},
		{
			path: '/*',
			redirect: () => {
				return store.getters['navigation/home'];
			}
		}
	)
}

router.beforeEach(async (to, from, next) => {
	
	//store last route 
	router.lastRoute = from;

	store.dispatch('navigation/updateRoute', to);
	
	next();

});

router.onError((error) => {
	const loadFailed = error.message.match(/loading.+failed/gi);
	if (loadFailed) {
		store.dispatch("alerts/error", strings.errors.internet.offline);
	}
});

function buildRoutes(list, children)
{
	_.each(list, (item) => {

		const { path, name, view, component, items, paramTitle, getterTitle } = item;
		
		const route = {
			name: path.replace(/^\/+/g, ''),
			path: path,
			meta: {
				title: name,
				paramTitle,
				getterTitle
			},
			children: []
		}

		if(view)
		{
			//dynamic load views
			route.component = () => import(/* webpackChunkName: "[request]", webpackPrefetch: true */ `@/views/${view}.vue`)
		} else if(component)
		{
			//dynamic load components
			route.component = () => import(/* webpackChunkName: "[request]" */ `@/components/${component}.vue`)
		}

		//build routes for menu items
		if(items)
		{
			buildRoutes(items, route.children);
		}

		//add to nested routes on recursive calls
		if(children)
		{
			children.push(route);
		} else {
			router.addRoute(route);
		}

		
	});
}

export default router
