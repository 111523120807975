import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'; 
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from "apollo-link-error";
import _ from 'lodash';

import env from '@/api/env';
import auth from '@/api/auth';
import store from '@/store/index';
import { strings } from "@/plugins/strings";
import { connectionMonitor } from '@/utils/connectionMonitor';

import UserDetails from '@/api/queries/user-details.graphql';
import WhatsNew from '@/api/queries/whats-new.graphql';
import EventsList from '@/api/queries/events-list.graphql';
import EventDetails from '@/api/queries/event-details.graphql';
import MyEvents from '@/api/queries/my-events.graphql';
import Feeds from '@/api/queries/feeds.graphql';
import FeedItem from '@/api/queries/feed-item.graphql';
import DirectoryList from '@/api/queries/directory-list.graphql';
import DirectorySearch from '@/api/queries/directory-search.graphql';
import ResidentDetails from '@/api/queries/resident-details.graphql';
import Restaurants from '@/api/queries/restaurants.graphql';
import Menus from '@/api/queries/menus.graphql';
import Menu from '@/api/queries/menu.graphql';
import HomeControlsConfig from '@/api/queries/home-controls-config.graphql';
import AlexaAddressBook from '@/api/queries/alexa-address-book.graphql';
import Shortcuts from '@/api/queries/shortcuts.graphql';

import EventSignup from '@/api/mutations/event-signup.graphql';
import EventWithdraw from '@/api/mutations/event-withdraw.graphql';
import SendInvite from '@/api/mutations/send-invite.graphql';
import SubmitForm from '@/api/mutations/submit-form.graphql';
import UpdateResident from '@/api/mutations/update-resident.graphql';
import AddAlexaContact from '@/api/mutations/alexa-contact-add.graphql';
import RemoveAlexaContact from '@/api/mutations/alexa-contact-remove.graphql';
import HideResidentProperties from '@/api/mutations/hide-resident-properties.graphql';
import ExposeResidentProperties from '@/api/mutations/expose-resident-properties.graphql';

export default {

	//login and user helpers

	setup() {

		const httpLink = createHttpLink({
			uri: env.api(),
			headers: {
				authorization: `Bearer ${this.jwt}`
			}
		});

		const errorLink = onError(({ networkError }) => {
			
			if (networkError && networkError.statusCode === 401) {

				setImmediate(()=>{
					localStorage.removeItem('user.jwt'); // jwt is possibly no good, so clear it
					localStorage.removeItem('vuex');
					location.href = env.login();
				})
				
			} else if (networkError && networkError.message.toLowerCase().includes("failed")) {
				store.dispatch("alerts/error", strings.errors.internet.offline);
			}
		});

		//create the papi client

		this.client = new ApolloClient({
			link: ApolloLink.from([ errorLink, httpLink ]),
			cache: new InMemoryCache(),
			defaultOptions: {
				query: {
					fetchPolicy: 'no-cache',
					errorPolicy: 'all'
				}
			}
		});
	},

	async loadJwt() {
		this.jwt = await auth.getCredentials();
		return this.jwt;
	},

	async init()
	{
		//note - this can be called multiple times during a long session. It is called by user store after token is refreshed

		await this.loadJwt();

		//make sure token is valid by getting 'me' from api
		let response;

		try {
			const community = store.getters['settings/value']('community')
			const { communityId } = JSON.parse(community);
			response = await this.query({
				query: UserDetails,
				variables: {
					communityId
				}
			});
		} catch(e) {
			console.log('unable to parse stored community from settings')
		}
			

		if(!response) {
			response = await this.query({
				query: UserDetails
			});
		}

		return response.data.me;
	},


	async getHomeControls(password)
	{

		const response = await this.query({
			query: HomeControlsConfig,
			variables: {
				cred: password,
			},
			fetchPolicy: 'no-cache',
		})
	
		return response.data.me.system;
	
	},

	async share(firstName, lastName, email, relationship, primaryPhone = null)
	{

		const response = await this.mutate({
			mutation: SendInvite,
			variables: {
				firstName, lastName, email, relationship, primaryPhone
			}
		})

		return response.data.me;

	},

	async updateProfile(biography)
	{

		const communityId = store.getters['user/communityId'];
		const residentId = store.getters['user/id'];
		
		return this.mutate({
			mutation: UpdateResident,
			variables: {
				communityId,
				residentId,
				edits: {
					biography
				}
			}
		})

	},

	async updateOptOutSettings(optOutOfDirectory) {
		
		const communityId = store.getters['user/communityId'];
		const residentId = store.getters['user/id'];
		
		return this.mutate({
			mutation: UpdateResident,
			variables: {
				communityId,
				residentId,
				edits: {
					optOutOfDirectory,
				},
			},
		});
	},

	async updateOptOutFields(fields) {
		
		const communityId = store.getters['user/communityId'];
		const residentId = store.getters['user/id'];
		
		return this.mutate({
			mutation: HideResidentProperties,
			variables: {
				communityId,
				input:{
					residentId,
					properties: fields
				}
				
			},
		});
	},

	async updateExposeFields(fields) {
		
		const communityId = store.getters['user/communityId'];
		const residentId = store.getters['user/id'];
		
		return this.mutate({
			mutation: ExposeResidentProperties,
			variables: {
				communityId,
				input:{
					residentId,
					properties: fields
				}
				
			},
		});
	},

	// feed helpers


	async getHome(from, after, limit = 50)
	{
		const communityId = store.getters['user/communityId'];
		const audiences = store.getters['user/audiences'];

		const response = await this.query({
			query: WhatsNew,
			variables: {
				communityId,
				filters: {
					audiences,
					status: 'Active',
					from
				},
				page: {
					after,
					limit
				}
			}
		})

		return response.data.community.feed;
	},

	async getFeed(category, tags, after, limit = 50)
	{


		const communityId = store.getters['user/communityId'];
		const audiences = store.getters['user/audiences'];

		const response = await this.query({
			query: Feeds,
			variables: {
				communityId,
				tagsInput: {
					category,
					sort: 'TagAsc',
					audiences,
					onlyActiveTags: true
				},
				filters: {
					tags,
					audiences,
					category,
					status: 'Active'
				},
				page: {
					after,
					limit
				}
			}
		})

		return response.data.community;
	},

	async getFeedItem(id)
	{

		//TODO - papi top level requests, mock for now
		if(id === '6b9f2e45-5b3d-4c74-9edd-39510cae78e4')
		{
			return _.find(MOCK_REQUESTS.feed.posts, [ '_id', '6b9f2e45-5b3d-4c74-9edd-39510cae78e4'])
		}

		const communityId = store.getters['user/communityId'];

		const response = await this.query({
			query: FeedItem,
			variables: {
				communityId,
				id
			}
		})

		return response.data.community.feedItem;
	},

	async submitForm(id, values)
	{

		const communityId = store.getters['user/communityId'];
		const userId = store.getters['user/id'];

		return this.mutate({
			mutation: SubmitForm,
			variables: {
				formId: id,
				data: JSON.stringify(values),
				communityId,
				userId
			}
		});

	},



	//dining

	async getRestaurants(first = 50)
	{
		const communityId = store.getters['user/communityId'];

		const response = await this.query({
			query: Restaurants,
			variables: {
				communityId,
				first
			}
		})

		const { edges } = response.data.community.restaurants;
		return _.map(edges, (item) => ({...item.node}));
	},

	async getMenus(restaurantId, first = 50)
	{
		const communityId = store.getters['user/communityId'];
		const audiences = store.getters['user/audiences'];

		const response = await this.query({
			query: Menus,
			variables: {
				communityId,
				restaurantId,
				first,
				audiences
			}
		})
		
		const { edges } = response.data.community.restaurant.availableMenusForThisWeek;
		return _.map(edges, (item) => ({...item.node}));
	},

	async getMenu(restaurantId, menuId, date, first = 50, timeframe = 'Day')
	{
		const communityId = store.getters['user/communityId'];
		const audiences = store.getters['user/audiences'];

		const response = await this.query({
			query: Menu,
			variables: {
				communityId,
				restaurantId,
				menuId,
				date,
				timeframe,
				first,
				audiences
			}
		})
		return response.data.community.restaurant.menu;
	},


	//directory

	async getDirectory(communityId)
	{
		

		const response = await this.query({
			query: DirectoryList,
			variables: {
				communityId
			}
		})

		return response.data.me.community;
	},

	async getDetails(id)
	{
		
		const response = await this.query({
			query: ResidentDetails,
			variables: {
				id
			}
		})

		return response.data.user;
	},

	async searchDirectory(communityId, query, roles)
	{
		const response = await this.query({
			query: DirectorySearch,
			variables: {
				communityId,
				query,
				roles
			}
		})

		return response.data.me.community.search.users;
	},

	async alexaAddressBook() {
		const response = await this.query({
			query: AlexaAddressBook
		})

		return response.data.me.alexaAddressBook;
	},

	async addAlexaContact({ roomName, phoneNumber, contactName } = {}) {
		const communityId = store.getters["user/communityId"];

		return this.mutate({
			mutation: AddAlexaContact,
			variables: {
				communityId,
				input: {
					roomName,
					contacts: [{
						type: "pstn",
						phoneNumbers: [phoneNumber],
						name: contactName,
					}],
				},
			},
		});
	},

	async removeAlexaContact({ roomName, contactName } = {}) {
		const communityId = store.getters["user/communityId"];

		return this.mutate({
			mutation: RemoveAlexaContact,
			variables: {
				communityId,
				input: { roomName, contactName },
			},
		});
	},

	//events

	async getEvents(page, limit, timeframe, price, calendars, search)
	{
		const communityId = store.getters['user/communityId'];

		const response = await this.query({
			query: EventsList,
			variables: {
				communityId, 
				page, 
				limit, 
				timeframe,
				calendars: calendars.length ? calendars : undefined,
				search
			}
		})

		const community = response.data.community;

		//TODO - this messes up pagination, PAPI should allow filter
		if(price !== 'any')
		{
			const costsMoney = (price === 'free') ? false : true;
			community.eventInstances.events = _.filter(community.eventInstances.events, ['costsMoney', costsMoney]);
		}

		return community;

	},


	async getEvent(eventId)
	{
		const communityId = store.getters['user/communityId'];

		const response = await this.query({
			query: EventDetails,
			variables: {
				communityId, 
				eventId
			}
		})

		return response.data.community.event;

	},

	async getMyEvents()
	{

		const response = await this.query({
			query: MyEvents
		})

		return response.data.me.events;

	},

	async attendEvent(eventId, guestName = null)
	{
		const communityId = store.getters['user/communityId'];
		const userId = store.getters['user/id'];

		const response = await this.mutate({
			mutation: EventSignup,
			variables: {
				communityId, 
				eventId,
				userId,
				guestName
			}
		})

		return response.data.community.event.signup;

	},

	async withdrawEvent(eventId, rsvpId)
	{
		const communityId = store.getters['user/communityId'];

		const response = await this.mutate({
			mutation: EventWithdraw,
			variables: {
				communityId, 
				eventId,
				rsvpId
			}
		})

		return response.data.community.event.withdraw;

	},

	// Shortcuts
	async getShortcuts() {
		const communityId = store.getters['user/communityId'];

		const response = await this.query({
			query: Shortcuts,
			variables: {
				communityId
			}
		});

		return response.data.community.shortcuts;
	},

	
	/*

	async getSignups()
	{
		return this.query({
			query: GET_SIGNUPS
		})
	},

	async createSignup(communityId, eventId, guestName)
	{
		const userId = store.getters('user/id');

		let variables = { communityId, eventId, userId };

		if(guestName)
		{
			variables.guestName = guestName;
		}

		return this.mutate({
			mutation: CREATE_SIGNUP,
			variables
		});
	},

	async withdrawSignup(communityId, eventId, rsvpId)
	{
		return this.mutate({
			mutation: WITHDRAW_SIGNUP,
			variables: {
				communityId, eventId, rsvpId
			}
		})
	},*/

	async query(options)
	{
		let response;
		const query = options.query.definitions[0].name.value;

		store.dispatch('navigation/loading', { [query]: true });
		const tm = connectionMonitor.track();

		try {
			response = await this.client.query(options);
			if(response.errors && response.errors.length > 0)
			{
				if(response.errors[0]?.message.indexOf('401') > -1) {
					localStorage.removeItem('user.jwt'); // jwt is possibly no good, so clear it
					localStorage.removeItem('vuex');
					connectionMonitor.resolve(tm);
					return location.href = env.login();
				}

				throw new Error(response.errors[0].message);
			}
		} catch (e) {
			store.dispatch('navigation/loading', { [query]: false });
			connectionMonitor.resolve(tm);
			throw e;
		}

		store.dispatch("navigation/loading", { [query]: false });
		connectionMonitor.resolve(tm);
		return response;

	},

	async mutate(options)
	{
		let response;
		const mutation = options.mutation.definitions[0].name.value;

		store.dispatch("navigation/loading", { [mutation]: true });
		const tm = connectionMonitor.track();

		try {
			response = await this.client.mutate(options);
			if(response.errors && response.errors.length > 0)
			{
				throw new Error(response.errors[0].message);
			}
		} catch(e) {
			store.dispatch("navigation/loading", { [mutation]: false });
			connectionMonitor.resolve(tm);
			throw e;
		}

		store.dispatch("navigation/loading", { [mutation]: false });
		connectionMonitor.resolve(tm);
		return response;

	},


	jwt: null,
	client: null
}


const MOCK_REQUESTS = {
	tags: [ 'Forms', 'Completed', 'Pending'],
	feed: {
		posts: [
			{
				_id: '6b9f2e45-5b3d-4c74-9edd-39510cae78e4',
				tag: 'Forms',
				title: 'Maintenance',
				startDate: '2022-03-12T19:48:43.356+00:00',
				category: 'Resources',
				body: 'Maintenance request form',
				assets: [
					{
						name: 'Maintenance Form',
						preview: null,
						type: 'Form',
						url: '/forms/workorder.json',
						__typename: 'Asset',
						details: {

						}
					}
				]
			},
			{
				tag: 'Completed',
				title: 'Sink Broken',
				startDate: '2022-03-12T19:48:43.356+00:00',
				category: 'Resources',
				body: 'Your sink was repaired. Please let us know if there are any other issues!'
			},
			{
				tag: 'Completed',
				title: 'Doctors Visit',
				startDate: '2022-01-12T19:48:43.356+00:00',
				category: 'Resources',
				body: 'Your transportation request was completed.'
			},
			{
				tag: 'Pending',
				title: 'Lightbulb Out',
				startDate: '2022-03-12T19:48:43.356+00:00',
				category: 'Resources',
				body: 'Maintenance will visit within 24 hours to fix the light bulb. Have a great day!'
			}

		]
	}
}
