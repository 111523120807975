import papi from "@/api/papi";
import _ from 'lodash';
import moment from "moment";

const state = () => ({
	posts: [],
	pageInfo: null,
	firstCall: true,
	cacheTime: null,
	latestPosition: "",
});

const getters = {

	loaded(state)
	{
		const EXPIRE = 7200000; //two hours
		return state.cacheTime && state.cacheTime + EXPIRE > Date.now();
	},

	isEmpty(state, getters) {
		return getters.loaded && (state.posts.length === 0 || !getters.hasMore);
	},
	hasMore(state) {
		return (
			state.firstCall || (state.pageInfo ? state.pageInfo.hasNextPage : false)
		);
	},
	latestPosition: state => state.latestPosition,
};

const mutations = {
	setFeed(state, { feed, more }) {
		const { posts, pageInfo } = feed;
		state.posts = more ? _.concat(state.posts, posts) : posts;
		state.pageInfo = pageInfo;
		state.cacheTime = Date.now();
	},
	reset(current) {
		Object.assign(current, state());
	},

	setLatestPosition(state, position) {
		state.latestPosition = position;
	},

	setFirstCall: (state, data) => (state.firstCall = data),
	setLoaded(state, value)
	{
		if(value)
		{
			state.cacheTime = Date.now();
		} else {
			state.cacheTime = null;
		}
	}
};

const actions = {
	load: async ({ commit, state, rootGetters }, more) => {
		if (!more) {
			commit("reset");
		}

		const lastLogin = rootGetters["user/lastLogin"];
		const from = more ? null : getFrom(lastLogin);
		const cursor =
			more && state.pageInfo && state.pageInfo.cursor
				? state.pageInfo.cursor
				: null; //cursor can be null when filtered by last login, but there is more

		const feed = await papi.getHome(from, cursor);
		commit("setFeed", { more, feed });
		if (more) {
			commit("setFirstCall", false);
		}

		commit("setLoaded", true);
	},
};

function getFrom(lastLogin) {
	const today = moment();
	const last = moment(lastLogin);

	const diff = today.diff(last, "days");
	const lastWeek = moment().subtract(1, "weeks").startOf("week");

	//if last login is more than 7 days ago, show since beginning of yesterday
	return diff > 7
		? lastWeek.toDate()
		: last.subtract(1, "day").startOf("day").toDate();
}


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
