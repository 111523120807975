import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		dark: true,
		themes: {
			light: {
				background: '#eaf4fa',
				secondary: '#444',
				navigation: '#fff',
				banner: '#97c7e7',
				panel: '#fefefe'
			},
			dark: {
				primary: '#ffd54b',
				secondary: '#ffffff',
				accent: '#66b3ff',
				background: '#283e4d',
				navigation: '#0B2130',
				banner: '#216491',
				panel: '#216491'
			}
		},
		options: {
			customProperties: true
		}
	}
});
