<template>
	<v-bottom-navigation app v-if="showTabs" color="accent">
		<v-layout fill-height justify-space-around align-center :style="`max-width: ${100*mobile.length}px;`">
			<div v-for="(tab, index) in mobile" :key="index">
				<v-menu v-if="tab.menu" close-on-click offset-y top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on">
							<span>{{tab.name}}</span>
							<v-icon>{{tab.icon}}</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item v-for="(item, i) in tab.items" :key="i" :to="item.to">
							<v-list-item-title>{{ item.name }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
				<v-btn v-else class="mobileBottomTabs"  @click="navigate(tab.to)" active-class="">
					<span>{{tab.name}}</span>
					<v-icon>{{tab.icon}}</v-icon>
				</v-btn>
			</div>
		</v-layout>
	</v-bottom-navigation>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
	name: 'MobileBottom',
	computed: {
		...mapState('navigation', [ 'mobile' ]),
		...mapGetters('navigation', [ 'showTabs' ])
	},
	data: () => ({
		value: null
	}),

	methods: {
		navigate(to) {
			if (this.$route.path.includes("events/details") && to.includes("events")) {
				this.$router.back();
			} else {
				this.$router.push(to)
			}
		}
	}

}
</script>

<style>
.mobileBottomTabs {
	padding: 0 5px !important;
	min-width: 60px !important;
}
</style>
