import { isSupported } from 'firebase/messaging'
import calls from '@/api/calls'
import { strings } from '@/plugins/strings'
import _ from 'lodash';

const state = () => ({
incomingCall: false,
	messageBody: null,
	apiKey: null,
	sessionId: null,
	token: null,
	to: null,
	from: null,
	callStatus: {
		started: false,
		answered: false,
		hungup: false,
		declined: false,
		cancelled: false,
		failed: false
	}
});


const getters = {
	async notificationsSupported() {
		return isSupported()
	},
	notificationsRequested(state) {
		return state.notificationsRequested;
	},
	messageInstance(state) {
		return state.messageInstance
	},
	onMessage(state) {
		return state.onMessage;
	},
	messageBody(state) {
		return state.messageBody
	},
	incomingCall(state) {
		return state.incomingCall
	},
	callStatus(state) {
		return state.callStatus;
	}
	
};

const mutations = {

	setIncomingCall(state, incomingCall = false) {
		state.incomingCall = incomingCall;
	},
	setApiKey(state, apiKey) {
		state.apiKey = apiKey;
	},
	setSessionId(state, sessionId) {
		state.sessionId = sessionId;
	},
	setToken(state, token) {
		state.token = token;
	},
	setTo(state, to) {
		state.to = to;
	},
	setFrom(state, from) {
		state.from = from;
	},
	setCallStatus(state, callStatus) {
		state.callStatus = callStatus;
	},

};

const actions = {
	
	async init() {
		await calls.init();
	},

	async callUser({ dispatch, rootGetters }, contact) {

		const isK4App = rootGetters['mobile/isK4App'];

		const caller = rootGetters["user/fullName"];
		const username = rootGetters["user/id"];
		const isVoiceCall = false;
		const to = _.get(contact, '_id');

		const { apiKey, sessionId, token } = await calls.call(caller, username, isVoiceCall, to, null);
		const payload = JSON.stringify({ apiKey, sessionId, token, caller, username, to });

		if (isK4App) {
			dispatch('mobile/call', payload, { root: true} );
		} else {
			dispatch('setSession', { apiKey, sessionId, token, to });
		}

	},

	setSession({ commit }, { apiKey, sessionId, token, to, from }) {
		commit('setApiKey', apiKey);
		commit('setSessionId', sessionId);
		commit('setToken', token);
		commit('setTo', to)
		commit('setFrom', from)
	},

	clearSession({ commit }) {
		commit('setApiKey', null);
		commit('setTo', null);
		commit('setFrom', null);
		commit('setSessionId', null);
		commit('setToken', null);
		commit('setIncomingCall', false);

		commit('setCallStatus', {
			started: false,
			answered: false,
			hungup: false,
			declined: false,
			cancelled: false,
			failed: false
		})
	},

	async cancelCall({ dispatch, state }, { declined, cancelled, hungup }) {

		const { apiKey, sessionId, token, to, from } = state;

		let user;
		if (cancelled && to) {
			user = to;
		} else {
			user = from;
		}

		if (sessionId) {
			await calls.cancel(user, apiKey, sessionId, token, declined, cancelled, hungup);
		}

		dispatch('clearSession');

	},

	async receive({ commit, dispatch }, payload) {


		const { apiKey, sessionId, token, username: from } = payload;

		dispatch('setSession', { apiKey, sessionId, token, from });
		commit('setIncomingCall', true);
	},

	async cancelled({ dispatch }) {
		dispatch('clearSession');
	},

	async declined({ dispatch }) {
		dispatch('clearSession');
		dispatch('alerts/info', strings.calls.declined, { root: true });
	},

	async setCallAnswered({ commit, state }, answered) {
		commit('setCallStatus', {...state.callStatus, answered});
	},

	async setCallStarted({ commit, state }, started) {
		commit('setCallStatus', {...state.callStatus, started});
	},

	
};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};