import papi from "@/api/papi";
import { set } from "@/utils/vuex";
import _ from 'lodash';

const state = () => ({
	posts: [],
	tags: [],
	tag: null,
	selected: null,
	category: null,
	pageInfo: null,
	cacheTime: null
});


const getters = {

	loaded(state)
	{
		const EXPIRE = 7200000; //two hours
		return state.cacheTime && state.cacheTime + EXPIRE > Date.now();
	},

	photos(state)
	{
		if(state.selected)
		{
			return _.filter(state.selected.assets, ['type', 'Photo']);
		}
	},
	isEmpty(state, getters)
	{
		return getters.loaded && state.posts.length === 0;
	},
	hasMore(state)
	{
		return (state.pageInfo) ? state.pageInfo.hasNextPage: false;
	},
	assets()
	{
		return (post)=>{
			const grouped = _.groupBy(post.assets, 'type')
			return _.flatten(
				[ grouped?.Photo && { ...grouped.Photo[0], photos: grouped.Photo,}, grouped?.Web, grouped?.Pdf, grouped?.Form ].filter((asset) => asset)
			);
		}

	}
};

const mutations = {

	setFeed(state, { feed, more })
	{
		const { posts, pageInfo } = feed;
		state.posts = (more) ? _.concat(state.posts, posts) : posts;
		state.pageInfo = pageInfo;
		state.cacheTime = Date.now();
	},
	reset(state, { category, tag })
	{

		if(state.category !== category)
		{
			state.category = null;
			state.tags = [];
		}

		if(state.tag !== tag)
		{
			state.posts = [];
			state.selected = null;
			state.pageInfo = null;
			state.cacheTime = null;
		}



	},
	setCategory: set('category'),
	setTags: set('tags'),
	setTag: set('tag'),
	setSelected: set('selected'),
	setLoaded(state, value)
	{
		if(value)
		{
			state.cacheTime = Date.now();
		} else {
			state.cacheTime = null;
		}
	}
};

const actions = {

	load: async ({commit, state}, { category, tag, more }) => {


		tag = (!tag || tag === 'all') ? null : tag;

		if(!more)
		{
			commit('reset', { category, tag });
		}




		const cursor = (more && state.pageInfo) ? state.pageInfo.cursor : null;

		const { feed, tags } = await papi.getFeed(category, tag, cursor);

		//TODO - why is this happening in client?
		_.each(feed.posts, (post)=>{
			_.each(post.assets, (asset)=> {
				asset.url = (asset.url) ? asset.url.replace('k4connect.com/v3', 'k4connect.com/v2') : null;
				asset.preview = (asset.preview) ? asset.preview.replace('k4connect.com/v3', 'k4connect.com/v2') : null;
			});
		})

		commit('setFeed', { more, feed });
		commit('setTags', tags);
		commit('setTag', tag);
		commit('setCategory', category);
	},

	select: async ({commit, state}, id) => {

		if(!id)
		{
			return commit('setSelected', null);
		}
		
		if(state.selected && state.selected._id === id)
		{
			return;
		}

		const post = await papi.getFeedItem(id);

		//TODO - why is this happening in client?
		_.each(post.assets, (asset)=> {
			asset.url = (asset.url) ? asset.url.replace('k4connect.com/v3', 'k4connect.com/v2') : null;
			asset.preview = (asset.preview) ? asset.preview.replace('k4connect.com/v3', 'k4connect.com/v2') : null;
		});

		commit('setSelected', post);
	},

	setSelected: async ({commit}, post) => {


		commit('setSelected', post);
	}

};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};