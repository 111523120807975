<template>
	<v-snackbar :color="color" top :value="!!current" timeout="-1">
		<div v-if="persistent">
			<span>
				{{current}}
			</span>
			<div class="text-right">
				<v-btn text small @click="$store.dispatch('alerts/dismissAlert')">
					Dismiss
				</v-btn>
			</div>
		</div>
		<span v-else>{{current}}</span>
	</v-snackbar>
</template>

<script>
import { mapState } from 'vuex';
export default {

	name: 'AlertBanner',

	computed: {
		...mapState('alerts', ['current', 'color', 'persistent'])
	},

}
</script>
