import stream from "@/api/stream";
import { set } from "@/utils/vuex";
import _ from "lodash";
import moment from "moment";
import { strings } from "@/plugins/strings";
import Store from "../";

const state = () => ({
	channel: null,
	channels: [],
	messages: [],
	users: [],
	cacheTime: null,
	lastLoaded: null,
	selected: null,
	unread: null,
	ready: false,
});

const MAX_SIZE = 20 * 1024 * 1024;
const LIMIT = 50;

const setMessageHandler = (e) => {
	const channel = Store.state.chat.channel;
	if (e.type.includes("reaction")) {
		// forces channel reset to remap reactions
		Store.commit("chat/setChannel", null);
		Store.commit("chat/setChannel", channel);
	} else if (e.type === "message.new") {
		// marks channel as read if user is on the channel
		if (Store.state.navigation.route.params.id === e.channel_id) {
			channel.markRead();
		}
	}
};

const getters = {

	loaded(state)
	{
		const EXPIRE = 7200000; //two hours
		return state.cacheTime && state.cacheTime + EXPIRE > Date.now();
	},

	channelTitle(state, getters, rootState, rootGetters) {
		if (state.channel) {
			const id = rootGetters["user/id"];

			const member = _.find(state.channel.state.members, (member) => {
				return member.user.id !== id;
			});
			return member.user.name;
		}
	},
	isEmpty(state, getters) {
		return getters.loaded && state.channels.length === 0;
	},

	hasMore(state) {
		if (state.channel) {
			return (
				state.lastLoaded > 0 && state.channel.state.messages.length >= LIMIT
			);
		}
	},

	messages: (state) => state.channel.state.messages.map(_.identity),

	grouped(state, getters, rootState, rootGetters) {
		if (state.channel) {
			const id = rootGetters["user/id"];
			const now = new Date();

			let first, last;

			const grouped = _.groupBy(getters.messages, (message) => {
				if (Array.isArray(message.attachments)) {
					//convert to grouped map
					message.attachments = _.groupBy(message.attachments, "type");
				}

				if (message.latest_reactions) {
					message.reactions = message.latest_reactions
						.map(({ user_id, data, score, type }) => ({
							own: user_id === id,
							text: `${data} ${score < 2 ? "" : score}`,
							data,
							type,
						}))
						.reverse();
				}

				const date = moment(message.created_at);
				if (date.isSame(now, "day")) {
					if (!first) {
						//save timestamp of first message
						first = date;
					} else {
						//start a new group if first message is more than 30 mins, or last message was more than 10 mins
						const firstDiff = date.diff(first, "minutes");
						const lastDiff = date.diff(last, "minutes");
						if (firstDiff > 30 || lastDiff > 10) {
							first = date;
						}
					}

					last = date;

					return `Today at ${first.format("h:mm A")}`;
				} else {
					last = date;
					return date.format("M/D/YYYY");
				}
			});

			//flag last message, per user, in each data group

			_.each(grouped, (messages) => {
				const userMessages = _.groupBy(messages, (message) => message.user.id);
				_.each(userMessages, (messages) => {
					_.each(messages, (message, i) => {
						message.last = i === messages.length - 1;
					});
				});
			});

			return grouped;
		}
	},

	channels(state) {
		return state.channels.map((channel) => {
			channel.unread = channel.state.unreadCount;
			return channel;
		});
	},
};

const mutations = {
	setChannels: set("channels"),
	setChannel: set("channel"),
	setMessages: set("messages"),
	setUsers: set("users"),
	setLastLoaded: set("lastLoaded"),
	setSelected: set("selected"),
	setUnread: set("unread"),
	setReady: set("ready"),
	setLoaded(state, value)
	{
		if(value)
		{
			state.cacheTime = Date.now();
		} else {
			state.cacheTime = null;
		}
	}
};

const actions = {
	async load({ commit, dispatch }) {
		await stream.login();
		dispatch("updateUnreadCount");
		commit("setReady", true);
	},
	async getChannels({ commit }) {
		await stream.login();

		const channels = await stream.getChannels(LIMIT);
		commit("setChannels", channels);
		commit("setLoaded", true);
	},

	async searchUsers({ commit }, query) {
		await stream.login();

		const users = await stream.searchUsers(query);
		commit("setUsers", users);
		return users;
	},

	async findStaff() {
		await stream.login();

		const users = await stream.searchUsers({ name: 'Community Staff' });
		return users;
	},

	async clearSearch({ commit }) {
		commit("setUsers", []);
	},

	async select({ commit, state }, id) {
		if (state.channel) {
			state.channel.off(setMessageHandler);
		}
		commit("setChannel", null);

		await stream.login();

		const channel = await stream.getChannel(id, LIMIT);

		channel.on(setMessageHandler);

		commit("setChannel", channel);
		commit("setLastLoaded", channel.state.messages.length);

		channel.markRead();
	},

	async more({ commit, state }) {
		const id = state.channel.state.messages[0].id;
		const response = await state.channel.query({
			messages: { limit: LIMIT, id_lt: id },
		});

		commit("setLastLoaded", response.messages.length);
	},

	async sendReaction({ state }, { reaction, id }) {
		return state.channel.sendReaction(id, reaction);
	},

	async removeReaction({ state }, { type, id }) {
		return state.channel.deleteReaction(id, type);
	},

	async send({ state }, { text, attachments = [] }) {
		return state.channel.sendMessage({
			text,
			attachments: attachments.length > 0 ? attachments : null,
		});
	},

	async edit(ctx, message) {
		return stream.updateMessage(message);
	},

	async uploadImages({ state, dispatch }, images) {
		return upload(dispatch, state, "image", images);
	},

	async uploadFiles({ state, dispatch }, files) {
		return upload(dispatch, state, "file", files);
	},

	async copyMessage({ dispatch }, text) {
		try {
			await navigator.clipboard.writeText(text);
			dispatch("alerts/info", strings.chat.copySuccess, { root: true });
		} catch (e) {
			console.error(e);
			dispatch("alerts/error", strings.chat.copyFail, { root: true });
		}
	},

	async createChannel({ commit, dispatch }, member) {
		const channel = await stream.createChannel(member);
		commit("setChannel", channel);
		await dispatch("getChannels");
		return channel;
	},

	async startChat({ dispatch }, userId) {
		await stream.login();

		const member = (await stream.searchUsers({ id: userId }))[0];

		if (!member) throw new Error("User does not have teams in common");

		const channel = await dispatch("createChannel", member);
		return channel.id;
	},

	async deleteMessage(ctx, id) {
		await stream.deleteMessage(id);
	},

	async clearChannel({ state }) {
		await state.channel.hide(null, true);
	},

	setSelected: async ({ commit }, attachments) => {
		commit("setSelected", attachments);
	},
	clearSelected: async ({ commit }) => {
		commit("setSelected", null);
	},

	updateUnreadCount({ commit, rootState, dispatch }, unread = null) {
		if (rootState.navigation.route.path === "/chat/channels") {
			dispatch("getChannels");
		}

		if (_.isNil(unread) && stream.client && stream.client.user) {
			unread = stream.client.user.unread_count;
		}

		commit("setUnread", unread);
	},
};

async function upload(dispatch, state, type, files) {
	const attachments = [];

	for (const file of files) {
		if (file.size >= MAX_SIZE) {
			dispatch("alerts/error", strings.chat.fileUpload.maximumSize, {
				root: true,
			});
		} else {
			try {
				const response =
					type === "image"
						? await state.channel.sendImage(file)
						: await state.channel.sendFile(file);
				const key = type === "image" ? "image_url" : "asset_url";
				attachments.push({
					[key]: response.file,
					type,
					mime: file.type,
					title: file.name,
				});
			} catch (e) {
				console.error(e);
				dispatch("alerts/error", strings.errors.generic, { root: true });
			}
		}
	}

	return attachments;
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
