export default {
	install(Vue)
	{
		const strings = Vue.prototype.$strings;

		Vue.prototype.$rules = {
			email: [
				v => !!v || strings.validation.email.required,
				v => /.+@.+\..+/.test(v) || strings.validation.email.valid,
			],
			password: [
				v => !!v || strings.validation.password.required
			],
			firstName: [
				v => !!v || strings.validation.firstName.required
			],
			lastName: [
				v => !!v || strings.validation.lastName.required
			],
			relationship: [
				v => !!v || strings.validation.relationship.required
			],
			code: [
				v => !!v || strings.validation.code.required,
				v => (v && v.length === 6) || strings.validation.code.valid
			],
			search: [
				v => (v && v.length >= 2) || strings.search.length(2)
			],
			guest: [
				v => !!v || strings.validation.guest.required
			],
		}
	}
}