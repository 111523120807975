import { Badge } from '@capawesome/capacitor-badge';

import { set, get } from "@/utils/vuex";

const state = () => ({
  badgeCount: 0
});


const getters = {
  badgeCount: get('badgeCount'),
};

const mutations = {
  setBadgeCount: set("badgeCount")
};

const actions = {

	async load({rootGetters, dispatch, commit}) {

    const chatNotificationCount = rootGetters['chat/newMessageCount'];
    const isNative = rootGetters['mobile/isK4App'];

    commit('setBadgeCount', chatNotificationCount);

    if(isNative) {
      dispatch('updateNativeBadgeCount')
    }

	},

  async updateNativeBadgeCount({state, rootGetters}) {

    const isNative = rootGetters['mobile/isK4App'];

    if(!isNative) {
      return;
    }

    const permission = await Badge.checkPermissions();

    if(!permission) {
      await Badge.requestPermissions();
    } else {
      await Badge.set({ count: state.badgeCount });
    }
      
  }

	
};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
