
import env from '@/api/env';
import store from '@/store/index';
import axios from 'axios';
import auth from '@/api/auth';
import { strings } from "@/plugins/strings";


export default {

	async init() {
		this.jwt = await auth.getCredentials();
	},


	async call(caller, username, isVoiceCall, to, sysid) {

		const response = await this.post(`${env.content()}/call`, { caller, username, isVoiceCall, to, sysid, credentials: { password: this.jwt, guid: username } });
		return response.data;
	},

	async cancel(user, apiKey, sessionId, token, declined, cancelled, hungup) {

		const users = [user];
		const message = { ...(declined && { declined }), ...(cancelled && { cancelled }), ...(hungup && { hungup }), call: { apiKey, sessionId, token } };
		const guid = store.getters['user/id'];

		const response =  await this.post(`${env.sso()}/notify`, { users, message, credentials: { password: this.jwt, guid } });
		return response.data;
	},

	async post(url, body) {
		let response;

		try {
			response = await axios.post(url, body);
		} catch (e) {
			console.log(e)
			if (e.message.toLowerCase().includes("network error")) {
				store.dispatch("alerts/error", strings.errors.internet.offline);
			}
			throw e;
		}
		return response;

	},

}
