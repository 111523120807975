<template>
	<div v-if="signedIn">
		<v-app-bar app clipped-left clipped-right :extension-height="4" color="navigation">
			<v-img v-if="permanent" max-height="50" max-width="120" contain :src="logoSrc" @click="$router.push(home)"/>
			<v-app-bar-nav-icon v-else @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-spacer/>
			<v-toolbar-title>{{title}}</v-toolbar-title>
			<v-spacer />
			<BarButtons/>
			<v-progress-linear v-show="isLoading" slot="extension" color="primary" indeterminate/>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" :permanent="permanent" clipped :bottom="isCompact" app color="navigation" class="navigationDrawer">
			<v-container v-if="!permanent">
				<v-img max-height="50" max-width="120" contain :src="logoSrc" @click="$router.push(home)"/>
			</v-container>
			<v-list nav dense>
				<div v-for="(item, i) in desktop" :key="i">
					<!-- contains items, render nested group -->
					<v-list-group v-if="item.items && !item.hideItems" append-icon="mdi-chevron-down" :data-qa="'sidebar-' + item.name" v-model="groups[item.name]">
						<template v-slot:activator>
							<v-list-item-icon>
								<v-icon>{{item.icon}}</v-icon>
							</v-list-item-icon>
							<v-list-item-title>{{item.name}}</v-list-item-title>
						</template>
						<template v-for="(subitem, j) in item.items" >
							<!-- contains menuComponent, a component provides the subitem-->
							<template v-if="subitem.menuComponent" >
								<keep-alive :key="j">
									<MenuComponent :name="subitem.menuComponent" />
								</keep-alive>
							</template>
							<!-- else render the subitem -->
							<template v-else>
								<v-list-item :key="j" v-if="visible(item)" @click="goto(subitem, item)" :input-value="isActive(subitem)">
									<v-list-item-icon>
									</v-list-item-icon>
									<v-list-item-title>{{subitem.name}}</v-list-item-title>
								</v-list-item>
							</template>
						</template>
					</v-list-group>
					<!-- single item, render the list item -->
					<v-list-item v-else-if="visible(item)" link :data-qa="'sidebar-' + item.name" @click="goto(item)" :input-value="isActive(item)">
						<v-list-item-icon>
							<v-icon>{{item.icon}}</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{item.name}}</v-list-item-title>
					</v-list-item>
				</div>
				
			</v-list>
			
			<template v-slot:append>
				<v-divider/>
				<v-list two-line>
					<v-list-item two-line @click="showProfileMenu = !showProfileMenu">
						<v-list-item-avatar>
							<UserAvatar :user="current" :size="40"/>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title>
								{{fullName}}
							</v-list-item-title>
							<v-list-item-subtitle>
								{{communityName}}
							</v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-icon>{{icon}}</v-icon>
						</v-list-item-action>
					</v-list-item>
					<v-list-item v-for="item in profileMenu" :key="item.name" :to="item.to" v-show="showProfileMenu && visible(item)" dense>
						{{item.name}}
					</v-list-item>
				</v-list>
		</template>
		<v-divider/>
		<div class="mt-5">
			<SearchBar />
		</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import UserAvatar from '@/components/util/UserAvatar.vue';
import BarButtons from '@/components/navigation/BarButtons.vue';
import MenuComponent from '@/components/navigation/MenuComponent.vue';
import SearchBar from '@/components/util/SearchBar.vue'

export default {
	name: "WebNavigation",
	components: {
		UserAvatar,
		BarButtons,
		MenuComponent,
		SearchBar
	},
	computed: {
		...mapState("navigation", ["desktop", "profileMenu"]),
		...mapGetters("navigation", ["title", 'isLoading', 'isMobile', 'isCollapsed', 'isCompact', 'home']),
		...mapState('user', ['current']),
		...mapGetters("user", ["signedIn", "communityName", "fullName" ]),
		...mapGetters("theme", ["logo"]),
		permanent()
		{
			return !this.isCollapsed;
		},
		logoSrc()
		{
			return this.logo.includes("://") ? this.logo : require(`@/assets/${this.logo}`);
		},
		visible()
		{
			return (item) => {
				if(item.if)
				{
					return !!this.$store.getters[item.if];
				} else if (item.not) {
					return !this.$store.getters[item.not];
				}
				return true;
			}

		},
		icon()
		{
			return (this.showProfileMenu) ? 'mdi-chevron-down' : 'mdi-chevron-up';
		}
	},
	methods: {
		goto(item, parent)
		{
			//use click method so we can control isActive
			const { path } = this.$route;
			const { category } = this.$route.params;

			if(category && category === item.name && path !== item.to)
			{
				//navigate internally inside same category using replace
				this.$router.replace({ path: item.to });
			} else if(path !== item.to)
			{
				this.$router.push({ path: item.to });
			}

			//reset group models

			const groups = {};
			if(parent)
			{
				groups[parent.name] = true;
			}

			this.groups = groups;

		},
		isActive(item)
		{
			//highlight if category matches, regardless of tag
			const { path } = this.$route;
			const { category, tag } = this.$route.params;
			const parts = item.to.split('/').slice(1);

			if(category && tag)
			{
				return category === parts[2];
			} else if (parts.length == 1){
				//handle simple routes that can become nested (ie. directory)
				return path.indexOf(`/${parts[0]}`) === 0;
			} else {
				return path === item.to;
			}

		}
	},
	data: () => ({
		drawer: null,
		showProfileMenu: false,
		groups: {}
	}),
};
</script>

<style>
.navigationDrawer:not(.v-navigation-drawer--is-mobile) {
	max-height: calc(100vh - 64px) !important;
}	
</style>
