import env from '@/api/env';
import axios from 'axios';
import store from '@/store/index';
import K4Login from '@k4connect/k4-login-client';
import firebase from '@/api/firebase';
import { Capacitor } from '@capacitor/core';

const k4Login = new K4Login();

export default {

	async getCredentials()
	{
		if(process.env.VUE_APP_LOCAL_JWT)
		{
			this.jwt = process.env.VUE_APP_LOCAL_JWT;
			return this.jwt;
		}

		const native = Capacitor.isNativePlatform();

		try {
			this.jwt = await k4Login.getCredentials();
		} catch (e) {

			//if we are on production web, attempt to migrate
			if(!native && env.name() === 'production')
			{
				//TODO - remove in future versions, once 3.x to 4.x migration is complete
				const token = await this.startMigration();
				const params = (token) ? `&migrate=${token}` : '';
				const url = env.login();
				location.href = `${url}${params}`;
			} else {
				location.href = env.login() + "&skipAuto=true";
			}
			
			return;
		}

		//TODO - remove in future versions, once 3.x to 4.x migration is complete
		if(!native)
		{
			await this.completeMigration();
		}

		
		return this.jwt;
	},


	/**
	 * 
	 * TODO - remove in future versions, once 3.x to 4.x migration is complete
	 */


	async startMigration()
	{
		const migrated = localStorage.getItem('web_migrated');
		if(migrated === 'complete')
		{
			//migration is complete,
			return;
		}

		const { auth } = firebase.instance();
		const exists = await firebase.exists();
	
		if(exists)
		{
			//if user, set migration as pending, register otp and return
			localStorage.setItem('web_migrated', 'pending');
			return new Promise((resolve)=>{
				
				let attempts = 0;

				const timer = setInterval(async ()=>{
					if(auth.currentUser)
					{
						clearInterval(timer);
						const token = await this.getMigrationToken(auth.currentUser);
						resolve(token);
					} else if(attempts > 10)
					{
						clearInterval(timer);
						resolve();
					} else {
						attempts++;
					}
				}, 1000);
					
			});
		} else {
			//if no firebase user, set migration as complete, return
			localStorage.setItem('web_migrated', 'complete');
		}
		

	},

	async getMigrationToken(user)
	{

		try {
			const firebaseIdToken = await user.getIdToken();
			await this.firebase(firebaseIdToken);
			return await this.getCustomToken();
		} catch(e) {
			console.error('Failed to save migration to OTP', e)
		}
		
	},

	async firebase(firebaseIdToken)
	{
		const response = await this.post(`${env.auth()}/firebase/login`, {
			firebaseIdToken
		},{
			credentials: 'include',
			headers: {
				'content-type': 'application/json',
			}
		});

		this.jwt = response.data;

		return response.data;

	},

	async getCustomToken()
	{
		const url = `${env.auth()}/customToken`;

		const response = await this.post(url, {}, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${this.jwt}`,
			}
		});

		if(response.status !== 200)
		{
			throw new Error('Access Denied');
		}

		const { token } = response.data;

		return token;

	},


	async completeMigration()
	{
		const migrated = localStorage.getItem('web_migrated');

		if(migrated === 'complete')
		{
			//migration is complete,
			return;
		}

		if(migrated === 'pending' && this.jwt)
		{
			// we successfully logged in, migration is complete
			localStorage.setItem('web_migrated', 'complete');

		}
	},

	/**
	 * 
	 * End migration code
	 */

	async getDevice()
	{
		const { guid } = this.parseJwt(this.jwt);
		const url = `${env.sso()}/associations/register`;
		
		store.dispatch('mobile/updateDeviceNameForWeb', guid)

		const name = store.getters['mobile/name'];

		const response = await this.post(url, { name, credentials: { guid, password: this.jwt }});

		return response.data.deviceId;
	},

	async logout()
	{
		await k4Login.logout();
		await store.dispatch('settings/remove', 'deviceId');
		await store.dispatch('settings/remove', 'community');
		await store.dispatch('settings/remove', 'lastFcmToken');
		await store.dispatch('settings/remove', 'lastPushKitToken');
		await store.dispatch('settings/remove', 'theme');
		await store.dispatch('mobile/clearMobileJWT');
		location.href = env.logout();
	},


	async chatLogin()
	{
		//get from localstorage as login isnt always called
		const url = `${env.auth()}/communication/login`;
		const communityId = store.getters['user/communityId'];

		const response = await this.post(url, {
			communityId
		}, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${this.jwt}`,
			}
		});

		return response.data.streamToken;

	},
	
	async post(url, body, options)
	{
		let response;

		store.dispatch('navigation/loading', { [url]: true });
		

		try {
			response = await axios.post(url, body, options);
		} catch(e) {
			store.dispatch('navigation/loading', { [url]: false });
			throw e;
		}

		store.dispatch('navigation/loading', { [url]: false });
		return response;

	},

	parseJwt (token) {
		var base64Url = token.split('.')[1];
		var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		
		var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));
	
		return JSON.parse(jsonPayload);
	}

}
