import { Bridge } from '@k4connect/capacitor-plugin'
import { set } from "@/utils/vuex";
import { Capacitor } from '@capacitor/core'
import { getAppVersion, getWebVersion } from "../../utils/version";
import _ from 'lodash';
import Vue from 'vue';

const state = () => ({
	settings: null,
	version: null,
	webVersion: null,
	initialized: false
});


const getters = {
	value(state)
	{
		return (key) => {
			if(state.settings)
			{
				return state.settings[key];
			}
		}
	},
	pushToken: (state) => {
		return state.settings.lastFcmToken;
	}
};

const mutations = {
	setSettings: set("settings"),
	setVersion: set("version"),
	setWebVersion: set("webVersion"),
	setInitialized: set("initialized"),
	setSetting(state, { name, value }) {
		if (!state.settings) {
			state.settings = {};
		}
		Vue.set(state.settings, name, value);
	}
};

const actions = {

	init: async ({commit, state, dispatch}) => {
		

		if(state.initialized)
		{
			//prevent initialization a second time. We pre-initalize settings in main.js, so dont need to do in store initialize loop
			return;
		}

		//load settings
		await dispatch('load');

		commit('setInitialized', true);

		

	},

	load: async ({commit}) => {
		//access directly here as mobile store is not initialized yet. All other files should use mobile/isK4App getter
		const native = Capacitor.isNativePlatform();

		const webVersion = await getWebVersion();
		commit("setWebVersion", webVersion);

		if(native)
		{
			const settings = await Bridge.getSettings();
			if(settings)
			{
				commit('setSettings', filterEmptyObjectValues(settings));
			}

			const version = await getAppVersion();
			commit("setVersion", version);

			
		} else {

			const settings = {};

			//load all from web localStorage, use K4_ prefix to filter
			for(let key in localStorage)
			{
				if(key.indexOf('K4_') === 0)
				{
					const actualKey = key.slice(3);
					settings[actualKey] = localStorage.getItem(key);
				}
			}

			commit('setSettings', filterEmptyObjectValues(settings));

		}

	},

	save: async ({dispatch, rootGetters, commit},  { name, value }) => {

		if(rootGetters['mobile/isK4App'])
		{
			await Bridge.setSetting({name, value: String(value)});
		} else {
			//save to web localStorage
			localStorage.setItem(`K4_${name}`, value);
		}

		//update state
		commit('setSetting', {name, value });

		await dispatch('init');
	},

	remove: async ({rootGetters, commit},  name) => {

		if(rootGetters['mobile/isK4App'])
		{
			await Bridge.setSetting({name, value: ''});
		} else {
			localStorage.removeItem(`K4_${name}`);
			localStorage.removeItem(`${name}`);

		}

		//update state
		commit('setSetting', {name, value: null });
	}
	
};

function filterEmptyObjectValues(obj) {
  return _.omitBy(obj, _.overSome([_.isNil, _.isNaN, isEmptyString]))
}

function isEmptyString(str){
	return typeof str == 'string' && !str?.length;
}


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};