import papi from "@/api/papi";
import _ from 'lodash';
import moment from 'moment';
import { set } from '@/utils/vuex';

const state = () => ({
	events: [],
	calendars: [],
	signups: [],
	selected: null,
	pageInfo: null,
	cacheTime: null,
	signupsLoaded: false,
	times: [
		{ name: "All", value: "Year" },
		{ name: "Today", value: "Day" },
		{ name: "This Week", value: "Week" },
		{ name: "This Month", value: "Month" },
	],
	prices: [
		{ name: "Any", value: "any" },
		{ name: "Free", value: "free" },
		{ name: "Costs Money", value: "costs" },
	],
	limit: 25,
	filters: {
		time: "Year",
		price: "any",
		calendars: [],
	}
});


const getters = {

	loaded(state)
	{
		const EXPIRE = 7200000; //two hours
		return state.cacheTime && state.cacheTime + EXPIRE > Date.now();
	},

	getEvent(state)
	{
		return function(id)
		{
			return _.find(state.events, [ '_id', id]);
		}
	},
	isEmpty(state, getters, rootState, rootGetters)
	{
		if(rootGetters['navigation/isSignups'])
		{
			return state.signupsLoaded && state.signups.length === 0;
		}
		return getters.loaded && state.events.length === 0;
	},
	hasMore(state)
	{
		return (state.pageInfo) ? state.pageInfo.hasNextPage: false;
	},

	showActions(state, getters, rootState, rootGetters) 
	{
		return rootGetters['user/hasRoles'](['Resident', 'Staff'])
	},

	demo(state, getters, rootState, rootGetters)
	{
		return rootGetters['user/hasRoles'](['Staff'])
	},

	skipSignups(state, getters, rootState, rootGetters)
	{
		return rootGetters['user/isStaff'] || rootGetters['user/isFamily'];
	},

	filterCount(state)
	{
		const { calendars, time, price } = state.filters;
		return calendars.length + ((time === 'Year') ? 0 : 1 ) + ((price === 'any') ? 0 : 1 );

	}

};

const mutations = {
	setEvents(state, { eventInstances, more }) {
		const { events, pageInfo } = eventInstances;
		state.events = more ? _.concat(state.events, events) : events;
		state.pageInfo = pageInfo;
		state.cacheTime = Date.now();
	},
	updateEvent(state, event) {
		const index = _.findIndex(state.events, { _id: event._id });
		if (index > -1) {
			state.events.splice(index, 1, event);
		}
	},
	reset(state) {
		state.events = [];
		state.pageInfo = null;
		state.cacheTime = null;
		state.page = 0;
		this.commit("resetFilters");
	},

	resetFilters(state) {
		state.filters = {
			time: "Year",
			price: "any",
			calendars: [],
		};
	},

	setSignups: set("signups"),
	setCalendars: set("calendars"),
	setSelected: set("selected"),
	setTimeFilter: set("filters.time"),
	setPriceFilter: set("filters.price"),
	setCalendarFilter: set("filters.calendars"),
	setSignupsLoaded: set("signupsLoaded"),
	setLoaded(state, value)
	{
		if(value)
		{
			state.cacheTime = Date.now();
		} else {
			state.cacheTime = null;
		}
	}
};

const actions = {
	init: async({dispatch}) => {
		await dispatch('queueEventReminders');
	},
	load: async ({ commit, state, rootGetters }, more) => {
		const { limit, pageInfo } = state;
		const { time, price, calendars } = state.filters;

		const page = more && pageInfo ? pageInfo.currentPage + 1 : 0;

		const { eventCalendars, eventInstances } = await papi.getEvents(
			page,
			limit,
			time,
			price,
			calendars		);

		const userId = rootGetters["user/id"];

		_.each(eventInstances.events, (event) => {
			addHelpers(event, userId);
		});

		commit("setEvents", { eventInstances, more });
		commit("setCalendars", eventCalendars);
	},

	signups: async ({ commit, getters, rootGetters }) => {
		if (getters.skipSignups) return commit("setSignupsLoaded", true);
		const signups = await papi.getMyEvents();

		const userId = rootGetters["user/id"];
		_.each(signups, (event) => {
			addHelpers(event, userId);
		});

		commit("setSignups", signups);
		commit("setSignupsLoaded", true);
	},

	select: async ({ commit, state, rootGetters }, id) => {

		if(!id)
		{
			return commit('setSelected', null);
		}
		
		if (state.selected && state.selected._id !== id) {
			commit("setSelected", null);
		}

		const event = await papi.getEvent(id);

		const userId = rootGetters["user/id"];
		addHelpers(event, userId);

		commit("setSelected", event);
	},

	refreshEvent: async ({ commit, rootGetters }, id) => {
		const userId = rootGetters["user/id"];
		const updatedEvent = await papi.getEvent(id);
		addHelpers(updatedEvent, userId);
		commit("updateEvent", updatedEvent);
	},

	refresh: async ({ dispatch, state }, id) => {
		await dispatch("refreshEvent", id);
		await dispatch("signups");
		await dispatch("queueEventReminders")
		if (state.selected && state.selected._id === id) {
			await dispatch("select", id);
		}
	},

	attend: async ({ dispatch }, { id, guest }) => {
		const status = await papi.attendEvent(id, guest);
		await dispatch("refresh", id);
		return status;
	},

	withdraw: async ({ dispatch }, { id, rsvp }) => {
		const status = await papi.withdrawEvent(id, rsvp);
		await dispatch("refresh", id);
		return status;
	},

	time: async ({ commit, dispatch }, value) => {
		commit("setTimeFilter", value);
		dispatch("load");
	},

	price: async ({ commit, dispatch }, value) => {
		commit("setPriceFilter", value);
		dispatch("load");
	},

	calendars: async ({ commit, dispatch }, value) => {
		commit("setCalendarFilter", value);
		dispatch("load");
	},
	resetFilters: async ({ commit, dispatch }) => {
		commit("resetFilters");
		dispatch("load");
	},
	queueEventReminders: async ({ getters, dispatch }) => {
		if (getters.skipSignups) return;

		async function sendReminders() {
			if (getters.skipSignups) return;

			const signups = await papi.getMyEvents();
			const eventReminders = generateRemindersFromMyEvents(signups);
			await dispatch('notifications/batchLocalNotifications', eventReminders, { root: true });
			setTimeout(sendReminders, 1000 * 60 * 15);
		}

		setTimeout(sendReminders, 1000 * 5);
	},
	clear({ commit, dispatch }) {
		commit("resetFilters");
		dispatch("load");
	},
};

function addHelpers(event, userId) {
	//find our rsvp

	event.guestRsvp = _.filter(event.rsvps, (event) => {
		return event.user._id === userId && event.__typename === "GuestRSVP";
	});

	event.rsvp = _.find(event.rsvps, (event) => {
		return event.user._id === userId && event.__typename === "UserRSVP";
	});

	event.hasWaitlist = event.totalSpots && event.openSpots < 1;
}

function calculateNotificationId(guid = "") {
	return parseInt(guid.split('-')[0].replace(/^[a-zA-Z]+/g, ''), 16).toString().slice(0, 8)
}

function getReminderMessage({startsAt, location, name, allDay}) {
	const start = moment(new Date(startsAt)).format('h:mm a');
	const eventLocation = location ? ` In ${location}` : ''
	const message = `${name} ${
		allDay ? 'an all day event' : `starting at ${start}`
	}.${eventLocation}`;
	return message;
}

function generateRemindersFromMyEvents(myEvents) {
	const fifteenMinutes = 1000 * 60 * 15;

	const filteredToFuture = _.filter(myEvents, (event) => moment(new Date(new Date(event.startsAt).getTime() - (fifteenMinutes))).isAfter())

	return _.map(filteredToFuture, (event) => {
		const message = getReminderMessage(event);
		const notificationId = calculateNotificationId(event._id);
		const allDayStart = new Date(event.startsAt).setHours(8)
		const scheduleAt = event.allDay ? new Date(new Date(allDayStart).getTime() - (fifteenMinutes)) : new Date(new Date(event.startsAt).getTime() - (fifteenMinutes))

		return {
			title: 'Upcoming Event',
			body: message,
			id: parseInt(notificationId),
			schedule: { at:  scheduleAt},
			actionTypeId: '',
			extra: {eventId: event._id},
		}
	});

}


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
