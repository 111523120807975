import env from '@/api/env';
import store from '@/store/index';
import axios from 'axios';
import auth from '@/api/auth';

export default {
	async query(query)
	{
		let response;

		const url = env.url('search', '/search');
		const communityId = store.getters['user/communityId'];

		store.dispatch('navigation/loading', { 'search': true });
		

		try {

			response = await axios.post(url, { communityId, query }, { 
				headers: {
					'Authorization': 'Bearer ' + auth.jwt
				}
			});
			
		} catch (e) {
			if(e.response && e.response.status === 401)
			{
				localStorage.removeItem('user.jwt'); // jwt is possibly no good, so clear it
				localStorage.removeItem('vuex');
				return location.href = env.login();
			}
			store.dispatch('navigation/loading', { 'search': false });
			throw e;
		}

		store.dispatch("navigation/loading", { 'search': false });
		return response.data;
	}
}
