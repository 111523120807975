import store from "@/store/index";

const connectionMonitor = (() => {
	const timeouts = new Map();
	let totalCalls = 0;
	let totalResolves = 0;
	let totalSlow = 0;

	setInterval(() => {
		console.log(
			`[connectionMonitor] total calls: ${totalResolves}, total slow: ${totalSlow}, ratio: ${connectionMonitor.ratio.toFixed(
				3
			)}`
		);
		store.commit("navigation/setSlowConnection", connectionMonitor.isSlow);

		totalCalls = 0;
		totalResolves = 0;
		totalSlow = 0;
	}, 600000); // 10 minutes

	return {
		track() {
			totalCalls++;

			const slowTimeout = setTimeout(() => {
				totalSlow++;
				if (connectionMonitor.isSlow) {
					store.dispatch("alerts/connection", "slow");
				}
			}, 5000);

			const offlineTimeout = setTimeout(() => {
				store.dispatch("alerts/connection", "offline");
			}, 30000);

			timeouts.set(slowTimeout, [slowTimeout, offlineTimeout]);

			return slowTimeout;
		},
		resolve(timeout) {
			const _timeouts = timeouts.get(timeout);
			_timeouts.forEach((t) => clearTimeout(t));
			timeouts.delete(timeout);
			totalResolves++;

			store.commit("navigation/setSlowConnection", connectionMonitor.isSlow);
		},
		get totalCalls() {
			return totalCalls;
		},
		get totalResolves() {
			return totalResolves;
		},
		get totalSlow() {
			return totalSlow;
		},
		get ratio() {
			return (totalSlow * 2) / Math.max(totalResolves + totalCalls, 1);
		},
		get isSlow() {
			return connectionMonitor.ratio > 0.5 && totalSlow > 1;
		},
	};
})();

window.cm = connectionMonitor;

export { connectionMonitor };
