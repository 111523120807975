
//import remote from '@/api/remote';
import { set, get } from '@/utils/vuex';
import env from '@/api/env';
import _ from 'lodash';

require("@k4connect/k4model2-js");

const state = () => ({
	k4: null,
	ready: false,
	error: null,
	loading: false,
});


const getters = {
	model(state)
	{
		//get model if k4 is ready
		if(state.k4 && state.ready)
		{
			return state.k4.model;
		}
	},
	group(state, getters)
	{
		const model = getters.model;
		return function(name)
		{
			if(model && model.groups[name])
			{
				return model.groups[name];
			}
			return [];
		}
	},
	lights(state, getters)
	{
		return getters.group('lights');
	},
	fans(state, getters)
	{
		return getters.group('fans');
	},
	thermostats(state, getters)
	{
		return getters.group('thermostats');
	},
	doors(state, getters)
	{
		return _.concat(getters.group('locks'), getters.group('barriers'))
	},
	windows(state, getters)
	{
		return getters.group('windows');
	},
	getDeviceById(state, getters)
	{
		const model = getters.model;
		return function(id)
		{
			if(model)
			{
				return model.guids[id];
			}
		}
	},
	error: get('error'),
	ready: get('ready'),
	loading: get('loading'),
};

const mutations = {
	setInstance: set("k4"),
	setReady: set("ready"),
	setError: set("error"),
	setLoading: set("loading"),
};

const actions = {

	async load({state, commit, rootGetters, dispatch })
	{

		if(state.k4 && state.k4.connected)
		{
			return;
		}
		commit("setLoading", true);

		const system = rootGetters['user/system'];

		if(system)
		{
			dispatch("navigation/loading", { k4Instance: true }, { root: true });

			const homeControls = rootGetters['user/homeControls'];
			const remoteUrl = env.remote();

			let url;

			try {
				const response = await fetch(`${remoteUrl}/remote?sysid=${homeControls.system}&ctoken=${homeControls.ctoken}&username=${homeControls.username}&password=${homeControls.password}`);
				const data = await response.json();
				url = 'https://' + data.host;
				commit("setLoading", false);
			} catch (e) {
				dispatch(
					"navigation/loading",
					{ k4Instance: false },
					{ root: true }
				);
				commit("setReady", false);
				commit("setLoading", false);
				commit("setError", "Failed to connect to home control. Please contact the staff for assistance if this persists.");
				dispatch("alerts/error", e, { root: true });
				return;
			}

			const instance = await dispatch(
				"k4/controller/connect",
				{
					url,
					listeners: {
						ready: () => {
							dispatch(
								"navigation/loading",
								{ k4Instance: false },
								{ root: true }
							);
							commit("setReady", true);
						},
						error: (e) => {
							if (e.includes("websocket error")) {
								// initial reconnect error, just ignore as it will reconnect or trigger another error
								return;
							}
							dispatch(
								"navigation/loading",
								{ k4Instance: false },
								{ root: true }
							);
							commit("setReady", false);
							commit("setError", e);
							dispatch("alerts/error", e, { root: true });
						},
						disconnected: () => {
							commit("setReady", false);
						},
					},
					...homeControls,
				},
				{ root: true }
			);

			//instance.logging.logLevel = 5;

			commit('setInstance', instance);

		}

	},


};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
