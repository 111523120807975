import { getAppVersion, getWebVersion } from "@/utils/version";
import env from '@/api/env';

/* eslint-disable */
;(function (apiKey) {
  ;(function (p, e, n, d, o) {
      let v
      let w
      let x
      let y
      let z
      o = p[d] = p[d] || {}
      o._q = []
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']
      for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
              o[m] =
                  o[m] ||
                  function () {
                      o._q[m === v[0] ? 'unshift' : 'push'](
                          [m].concat([].slice.call(arguments, 0))
                      )
                  }
          })(v[w])
      y = e.createElement(n)
      y.async = !0
      y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`
      z = e.getElementsByTagName(n)[0]
      z.parentNode.insertBefore(y, z)
  })(window, document, 'script', 'pendo')
})('160064b8-62f2-44c1-4fe3-cf5a6e36194a')



export default {
  async initialize(user, communityName, deviceInfo, dark, theme) {

    const userId = user._id;
    const role = user.roles[0];
    const name = user.fullName;
    const communityId = user.community.communityId;
	const version = await getAppVersion();
	const webVersion = await getWebVersion();
	const { platform } = deviceInfo;

	const visitor = { id: userId, role, name, platform, dark, theme };
	const account = { id: communityId, communityName, env: env.name() };

	if(version)
	{
		visitor.version = version;
	}

	if(webVersion)
	{
		visitor.webVersion = webVersion;
	}

    if(env !== 'dev') {
      pendo?.initialize({
        visitor, account 
      });
    } else {
      console.log('dev env detected: ignoring pendo initializiation.')
    }
    
  }
}


