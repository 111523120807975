
import { getToken } from 'firebase/messaging'
import env from '@/api/env';
import store from '@/store/index';
import axios from 'axios';
import auth from '@/api/auth';
import firebase from '@/api/firebase';


export default {

	async init() {

		this.jwt = await auth.getCredentials();

		if(!this.jwt)
		{
			//redirect will happen, just exit
			return;
		}

		const { messaging } = firebase.instance();

		const notificationsGranted = await store.dispatch('notifications/notificationsGranted')
		const device = store.getters['settings/value']('deviceId');
		const type = 'firebase';
		const guid = store.getters['user/id'];

		if (notificationsGranted && device && guid) {
			const token = await getToken(messaging);
			await this.post(`${env.sso()}/pushtoken`, { token, device, type, credentials: { password: this.jwt, guid } });

		} else {
			console.log('notification requirements unmet')
		}
	},


	async post(url, body) {
		let response;

		try {
			response = await axios.post(url, body);
		} catch (e) {
			console.log(e)
			throw e;
		}
		return response;

	},

}