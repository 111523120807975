import Vue from 'vue'
import Vuex from 'vuex'
import { forEachModule } from "@/utils/vuex";
import VuexPersistence from 'vuex-persist';
Vue.use(Vuex)
import { Capacitor } from '@capacitor/core';

import { HAStorePlugin } from "@k4connect/project-merlin-shared-home-automation";

import user from '@/store/modules/user';
import settings from '@/store/modules/settings';
import navigation from '@/store/modules/navigation';
import alerts from '@/store/modules/alerts';
import feed from '@/store/modules/feed';
import chat from '@/store/modules/chat';
import mobile from '@/store/modules/mobile';
import events from '@/store/modules/events';
import whatsnew from '@/store/modules/whatsnew';
import directory from '@/store/modules/directory';
import dining from '@/store/modules/dining';
import controls from '@/store/modules/controls';
import notifications from '@/store/modules/notifications';
import calls from '@/store/modules/calls';
import forms from '@/store/modules/forms';
import theme from '@/store/modules/theme';
import flags from '@/store/modules/flags';
import badges from '@/store/modules/badges';
import shortcuts from '@/store/modules/shortcuts';
import search from '@/store/modules/search';


import { App } from '@capacitor/app';

import env from '@/api/env';

const modules = {
	settings,
	mobile, //settings & mobile should be loaded first
	navigation,
	user,
	alerts,
	feed,
	chat,
	whatsnew,
	directory,
	dining,
	controls,
	notifications,
	events,
	calls,
	forms,
	flags,
	theme,
	badges,
	shortcuts,
	search
};

const actions = {
	async initialize({ dispatch }) {
		await forEachModule(modules, { dispatch }, "init");
	},
	async resetState({ commit }) {
		await forEachModule(modules, { commit }, "resetState");
	},
};

const plugins = [HAStorePlugin()];

//create persistent storage on non-dev
if(env.name() !== "dev" && Capacitor.isNativePlatform()) {
	const vuexLocal = new VuexPersistence({
		storage: window.localStorage,
		modules: ['whatsnew','dining', 'directory', 'events', 'flags', 'theme']
	})

	//plugins
	plugins.push(vuexLocal.plugin)
}


const store = new Vuex.Store({ actions, modules, plugins });

App.addListener('resume', async ()=>{

	const fromBackground = await store.dispatch('mobile/resume');

	if(fromBackground)
	{
		//reload some stores on resume from background

		//dispatch sync, will handle internal
		store.dispatch('mobile/checkForDeepLink', true);

		if (store.state.navigation.route.path !== '/library/new' || !store.getters['whatsnew/loaded']) {
			await store.dispatch('whatsnew/load');
		}

		await store.dispatch('events/load', false);

		await store.dispatch('directory/load', true);

		await store.dispatch('badges/load', true);


	}


});

export default store;
