import papi from "@/api/papi";
import { set } from "@/utils/vuex";
import _ from 'lodash';

const state = () => ({
	residents: [],
	friends: [],
	staff: [],
	results:[],
	cacheTime: null,
	selected: null,
	baseProfileImage: 'https://k4connect-shared.s3.amazonaws.com/misc/profile.svg',
	roles: {
		residents: 2,
		friends: 3, 
		staff: 4
	}
});


const getters = {

	loaded(state)
	{
		const EXPIRE = 7200000; //two hours
		return state.cacheTime && state.cacheTime + EXPIRE > Date.now();
	},
	
	isEmpty(state, getters, rootState)
	{
		if(state.query)
		{
			return state.resultsLoaded && state.results.length === 0;
		}

		switch(rootState.navigation.route.params.role)
		{
			
			case 'residents':
				return getters.loaded && state.residents.length === 0;
			case 'friends':
				return getters.loaded && state.friends.length === 0;
			case 'staff':
				return getters.loaded && state.staff.length === 0;
		}
		
	}
};

const mutations = {
	
	setStaff: set('staff'),
	setResidents: set('residents'),
	setFriends: set('friends'),
	setSelected: set('selected'),
	setResults: set('results'),

	setLoaded(state, value)
	{
		
		if(value)
		{
			state.cacheTime = Date.now();
		} else {
			state.cacheTime = null;
		}

		console.log('setLoaded', state.cacheTime);
	},

	reset(state)
	{
		state.residents = [];
		state.friends = [];
		state.staff = [];
		state.results = [];
		state.selected = null;
		state.cacheTime = null;
	}
};

const actions = {

	load: async ({commit, rootGetters}, soft = false) => {
		
		if(!soft)
		{
			commit('reset');
		}
		
		const communityId = rootGetters['user/communityId'];
		const { directory, staffDirectory } = await papi.getDirectory(communityId);
		const currentId = rootGetters['user/id'];
		const friends = rootGetters['user/isStaff'] ? [] : _.chain(directory)
			.filter([ 'family', true])
			.reject({'_type': 'Resident'})
			.reject({'_id': currentId})
			.value();

		const staff = _.chain(staffDirectory)
			.reject({'_id': currentId})
			.value();

		const residents = _.chain(directory)
			.filter(['_type', 'Resident'])
			.filter(['optOutOfDirectory', false])
			.sortBy(({_id}) => _id !== currentId)
			.value();

		commit('setResidents', residents);
		commit('setFriends', friends);
		commit('setStaff', staff);
		commit('setLoaded', true);
	},

	select: async ({commit}, id) => {

		if(!id)
		{
			return commit('setSelected', null);
		}

		const details = await papi.getDetails(id);
		commit('setSelected', details);
			
		

	},

	guestSearch: async({state, dispatch, getters, commit}, search) => {

		if(_.isEmpty(search))
		{
			return;
		}

		if(!getters.loaded)
		{
			await dispatch('load');
		}

		const friends = _.filter(state.friends, (friend)=>{
			return (
				friend.fullName && friend.fullName.toLowerCase().indexOf(search.toLowerCase()) > -1
			)
		})

		const residents = _.filter(state.residents, (resident)=>{
			return (
				resident.fullName && resident.fullName.toLowerCase().indexOf(search.toLowerCase()) > -1
			)
		})


		const merged = _.concat(friends, residents);
		const results =  _.orderBy(merged, ['lastName', 'firstName'], ['asc', 'asc']);
		commit('setResults', results);
	},

	clearSearch: async({commit}) => {
		commit('setResults', []);
	}

	
};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
