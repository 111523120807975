import _ from 'lodash';

export default {
	install(Vue)
	{
		Vue.mixin({
			mounted: function () {
				const name = getName(this);
				if(name && typeof this.$el.dataset === 'object')
				{
					this.$el.dataset.cy = name;
				}
			}
		})
	}
}

function getName(component, suffix = '')
{
	if(!component)
	{
		return;
	}

	const name = component.$options.name;

	if(!name)
	{
		return;
	}

	if(name.indexOf('v-') == 0)
	{
		return getName(component.$parent, '_' + name + suffix);
	} else {
		return _.kebabCase(name) + suffix;
	}


}