<template>
	<div>
		<ConfirmDialog v-model="show" :body="messageBody" :cancel="$strings.calls.incomingDialog.cancel" :ok="$strings.calls.incomingDialog.confirm" @cancel="declineCall" @confirm="acceptCall"/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ConfirmDialog from '@/components/util/ConfirmDialog.vue';

// import _ from 'lodash';


export default {
	name: "IncomingCallDialog",
	components: {
		ConfirmDialog,
	},
	computed: {
		...mapGetters('notifications', ['messageBody']),
		...mapGetters('calls', [ 'incomingCall' ]),
		show: {
			get()
			{
				return this.incomingCall;
			},
			set()
			{

			}
		}
	},

	watch: {

	},

	async mounted()
	{
		// await this.$store.dispatch('chat/select', this.$route.params.id);
	},

	methods: {

    async acceptCall() 
    {
      this.$router.push('/calls');
			await this.$store.dispatch('calls/setCallAnswered', true)
    },

    async declineCall() 
    {
      await this.$store.dispatch('calls/cancelCall', { declined: true })
    }
		
	},
	data: () => ({
		message: null,
	})
};
</script>
