<template>
	<div style="min-width: fit-content;">
		<v-btn v-if="isOffline" icon @click="showOffline()">
			<v-icon>mdi-lan-disconnect</v-icon>
		</v-btn>
		<v-btn v-else-if="slowConnection" icon @click="showSlowConnection()">
			<v-icon>mdi-lan-pending</v-icon>
		</v-btn>

		<v-btn v-if="isChatHome" :class="{ hide: hideChat }" icon to="/chat/users">
			<v-icon>mdi-account-plus</v-icon>
		</v-btn>
		<v-btn v-else-if="ready" :class="{ hide: hideChat }" icon to="/chat/channels">
			<v-badge :content="unread" :value="unread > 0" overlap color="accent">
				<v-icon>mdi-chat</v-icon>
			</v-badge>
		</v-btn>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
	name: 'BarButtons',
	computed: {
		...mapState("user", ["current"]),
		...mapState("chat", ["ready", "unread"]),
		...mapGetters("user", ["isStaff"]),
		...mapGetters("navigation", ["slowConnection", "isOffline"]),
		hideChat()
		{
			//as this is a navigation component, we should be careful store getters are available early in bootstrap
			return (this.current) ? this.isStaff : true;
		},
		isChatHome()
		{
			return this.$route.path === '/chat/channels';
		},
	},
	watch: {
		slowConnection(slow) {
			if (!this.isOffline && !slow) {
				this.$store.dispatch("alerts/dismissAlert");
			}
		}
	},
	methods: {
		showSlowConnection() {
			sessionStorage.removeItem('slowConnectionShown');
			this.$store.dispatch("alerts/connection", "slow");
		},
		showOffline() {
			this.$store.dispatch("alerts/connection", "offline");
		}
	}
}
</script>

<style lang="scss" scoped>
// Hide to keep top bar alignment instead of removing it from the DOM
.hide {
	visibility: hidden;
	pointer-events: none;
}
</style>
