<template>
	<v-dialog v-model="dialog" :width="modalWidth" persistent>
		<v-card color="background">
			<v-card-title>{{title}}</v-card-title>
			<v-card-text>{{body}}</v-card-text>
			<v-card-actions>
				<v-btn v-if="cancel" text @click="decline">{{cancel}}</v-btn>
				<v-spacer/>
				<v-btn color="primary" text @click="confirm">{{ok}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
	name: 'ConfirmDialog',
	props: {
		value: Boolean,
		title: String,
		body: String,
		cancel: String,
		ok: String
	},
	computed: {
		...mapGetters('navigation', ['modalWidth'])
	},
	watch: {
		value()
		{
			this.dialog = this.value;
		}
	},
	mounted()
	{
		this.dialog = this.value;
	},
	methods: {
		close()
		{
			this.dialog = false;
			this.$emit('input', false);
		},
		decline()
		{
			this.$emit('cancel');
			this.close();
		},
		confirm()
		{
			this.$emit('confirm');
			this.close();
		},
	},
	data: () => ({
		dialog: false
	})
}
</script>
