<template>
	<v-app id="app" @dblclick.native.prevent>
		<AlertBanner />
		<WebNavigation v-if="showWeb"/>
		<MobileTop v-if="showTabs"/>
		<IncomingCallDialog v-if="!isK4App"/>
		<NotificationBanner v-if="!isK4App"/>
		<LoadingOverlay v-if="!visible"/>
		<v-main>
			<v-container>
				<router-view />
			</v-container>
		</v-main>
		<MobileBottom v-if="!hideTabBar"/>
	</v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AlertBanner from '@/components/util/AlertBanner.vue';
import MobileTop from '@/components/navigation/MobileTop.vue';
import MobileBottom from '@/components/navigation/MobileBottom.vue';
import WebNavigation from '@/components/navigation/WebNavigation.vue';
import IncomingCallDialog from "./components/calls/IncomingCallDialog.vue";
import NotificationBanner from "./components/util/NotificationBanner.vue";
import LoadingOverlay from "./components/util/LoadingOverlay.vue";


export default {
	components: {
		AlertBanner,
		MobileTop,
		MobileBottom,
		WebNavigation,
		IncomingCallDialog,
		NotificationBanner,
		LoadingOverlay,
	},
	computed: {
		...mapState("navigation", ["visible"]),
		...mapGetters("navigation", ["showWeb", "showTabs", "hideTabBar"]),
		...mapGetters("mobile", [ 'isK4App' ])
	}
};
</script>

<style>

body {
	background-color: black;
}
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	word-break:keep-all !important;
}

.v-card__title {
	word-break: keep-all !important;
}

.v-main {
	background-color: var(--v-background-base) !important;
}

.v-toolbar__extension {
	padding: 0px !important;
}

.v-list-item--active {
	color: var(--v-primary-base) !important;
}

.v-input .v-input__icon--prepend-inner {
	margin-right: 10px;
}

.v-overlay__scrim {
	user-select: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
div::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

* {
	touch-action: manipulation;
}
</style>
