<template>
	<v-app-bar app :extension-height="4" color="navigation" clipped-right clipped-left fixed>
		<v-btn v-if="showBack" icon @click="$router.back()" class="backButton" plain>
			<v-icon>mdi-chevron-left</v-icon>
		</v-btn>
		<v-avatar v-else size="40" class="navigationAvatar" @click="profile" color="grey darken-2">
			<v-img v-if="current" :src="current.profileImage"></v-img>
		</v-avatar>
		<v-spacer/>
		<v-toolbar-title v-if="title">{{title}}</v-toolbar-title>
		<v-spacer/>
		<BarButtons/>
		<v-progress-linear v-show="isLoading" slot="extension" color="primary" indeterminate/>
	</v-app-bar>
</template>
<script>

import { mapGetters, mapState } from 'vuex';
import BarButtons from '@/components/navigation/BarButtons.vue';

export default {
	name: 'MobileTop',
	components: {
		BarButtons
	},
	computed: {
		...mapGetters('navigation', ['title', 'showBack', 'isLoading']),
		...mapState("user", ["current"])
	},
	methods: {
		profile()
		{
			this.$router.push({ path: '/profile' });
		}
	},
	data: () => ({
		value: null
	})

}
</script>

