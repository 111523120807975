<template>
	<v-form ref="form" @submit.prevent="">
		<v-text-field v-model="search" @keydown.enter.prevent="submit" @click:clear="clear" clearable  :label="$strings.search.label" outlined background-color="navigation" class="search ma-3" hide-details>
			<template v-for="(index, name) in $slots" v-slot:[name]>
				<slot :name="name" />
			</template>
			<template slot="append">
				<v-btn color="primary" small icon @click="submit" :disabled="!search">
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
			</template>
		</v-text-field>
	</v-form>

</template>

<script>

import { mapState }  from 'vuex';

export default {
	name: 'SearchBar',
	computed: {
		...mapState('search', [ 'text', 'meta' ]),
	},
	mounted()
	{
		this.search = this.text;
	},
	watch: {
		text()
		{
			if(this.text !== this.search)
			{
				this.search = this.text;
			}
		}
	},
	methods: {
		async submit()
		{
			if(!this.search)
			{
				return;
			}

			const filtered = this.filter(this.search);

			try {
				await this.$store.dispatch('search/submit', { text: filtered});
			} catch(e) {
				console.error(e);
				this.$store.dispatch('alerts/error', this.$strings.errors.search);
				return;
			}
			
			
			if(this.$route.path !== '/search')
			{
				this.$router.push('/search');
			}
			
		},
		clear()
		{
			this.$store.dispatch('search/clear');
		},
		filter(text)
		{
			return text.replace(/[^0-9a-z\-_'$*&^%#@!+=".?:;\s]/gi, '');
		}
	},
	data: () => ({
		search: null
	})
}
</script>

<style>
.search .v-input__append-outer,
.search .v-input__prepend-outer {
	margin-top: 10px !important;
}
</style>
