
import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app'
import { getMessaging, deleteToken } from 'firebase/messaging'
import { getAuth, onAuthStateChanged, initializeAuth, indexedDBLocalPersistence } from 'firebase/auth'
import env from '@/api/env';

const firebaseConfig = env.firebaseConfig();

export default {


	instance() {

		if(!this.app)
		{
			this.app = initializeApp(firebaseConfig)
			this.messaging = getMessaging(this.app)
			
			if (Capacitor.isNativePlatform()) {
				this.auth = initializeAuth(this.app, {
					persistence: indexedDBLocalPersistence
				})
			} else {
				this.auth = getAuth()
			}

			onAuthStateChanged(this.auth, ()=>{
				const { currentUser } = this.auth;
				this.currentUser = currentUser;
			})
			
		}

		return { messaging: this.messaging, auth: this.auth };
		

	},

	async deleteFirebaseToken() {
		try {
			await deleteToken(this.messaging);
		} catch(e) {
			console.warn('No Firebase Token present')
		}
	},

	async exists()
	{

		return new Promise((resolve)=>{
			const dbRequest = window.indexedDB.open("firebaseLocalStorageDb");
			dbRequest.onsuccess = () => {

				try {
					const db = dbRequest.result;
					const tx = db.transaction(["firebaseLocalStorage"], 'readonly');
					const store = tx.objectStore("firebaseLocalStorage");
					const countRequest = store.count();

					countRequest.onsuccess = () => {
						resolve(countRequest.result > 0);
					}

					countRequest.onerror = () => {
						resolve(false);
					}
					
				} catch(e) {
					resolve(false);
				}
				
			}

			dbRequest.onerror = () => {
				resolve(false);
			}
		});
  
	}


}