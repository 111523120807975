import { LocalNotifications }  from '@capacitor/local-notifications';
import { PushNotifications }  from '@capacitor/push-notifications';
import { isSupported, onMessage } from 'firebase/messaging'
import firebase from '@/api/firebase'
import { strings } from '@/plugins/strings';
import _ from 'lodash';
import router from '@/router/index';
import { set, get } from "@/utils/vuex";
import notifications from '@/api/notifications';

const state = () => ({
	notificationsRequested: false,
	messageInstance: null,
	onMessage: null,
	messageBody: null,
	cacheTime: null
});


const getters = {

	loaded(state)
	{
		const EXPIRE = 7200000; //two hours
		return state.cacheTime && state.cacheTime + EXPIRE > Date.now();
	},
	
	async notificationsSupported() {
		return isSupported()
	},

	notificationsRequested: get('notificationsRequested'),
	messageInstance: get('messageInstance'),
	onMessage: get('onMessage'),
	messageBody: get('messageBody'),

};

const mutations = {

	setNotificationsRequested: set("notificationsRequested"),
	setMessageInstance: set("messageInstance"),
	setOnMessage: set("onMessage"),
	setMessageBody: set("messageBody"),
	setLoaded(state, value)
	{
		if(value)
		{
			state.cacheTime = Date.now();
		} else {
			state.cacheTime = null;
		}
	}

};

const actions = {

	async init({rootGetters, dispatch}) {


		//only do on mobile app
		if(rootGetters['mobile/isK4App'])
		{
			PushNotifications.addListener('pushNotificationActionPerformed', async (data) => {

				//push notification was tapped
				const { notification } = data;
	
				if(notification.data && notification.data.origin === 'getStream')
				{
					const { channelId } = notification.data;
					const to = `/chat/details/${channelId}`;

					//select the target channel
					await dispatch('chat/select', channelId, { root: true });
					router.push(to);
				}
				
			})
	
			LocalNotifications.addListener('localNotificationActionPerformed', (data) => {
				const { notification } = data;
				const { extra } = notification;
	
				//local notification from k4connect was tapped by user
				if(extra && extra.resourceId)
				{
					//for now treat all as urgent notification - in future meta will need a type added (in papi > notification service)
					const to = rootGetters['navigation/home'];
					router.push(to);
				}

				if(extra && extra.eventId) 
				{
					const to = `/events/details/${extra.eventId}`
					router.push(to);
				}
	
			})

			//permissions are handled in native layer as is token registration

			await PushNotifications.register();
		} 
		


	},

	async load({ getters, commit, dispatch, rootGetters }) {

		const isK4App = rootGetters['mobile/isK4App'];
		
		//if we're on web on notifications are supported
		if (!isK4App) {
			//init firebase push (web)
			const notificationsSupported = await getters['notificationsSupported'];

			const notificationsRequested = rootGetters['settings/value']('notifications') === 'true';
			commit('setNotificationsRequested', notificationsRequested)

			if (notificationsSupported) {

				await notifications.init()
				const { messaging } = firebase.instance();

				commit('setMessageInstance', messaging);
				commit('setOnMessage', onMessage);

				//handle incoming notifications
				onMessage(messaging, async (message) => dispatch('onMessageHandler', message));

				// Listen to service worker messages sent via postMessage()
				navigator.serviceWorker.addEventListener('message', async (event) => dispatch('onServiceWorkerMessageHandler', event))

				commit('setLoaded', true);
				return;
			}


		}
		await dispatch('denyWebNotificationAccess');
	},

	async grantWebNotificationAccess({ commit, dispatch }) {

		await Notification.requestPermission();
		await dispatch('settings/save', { name: 'notifications', value: 'true'}, { root: true });
		commit('setNotificationsRequested', true);
		dispatch('load')

	},

	async denyWebNotificationAccess({ commit, dispatch }) {
		await dispatch('settings/save', { name: 'notifications', value: 'true'}, { root: true });
		commit('setNotificationsRequested', true);
	},

	async clearWebNotificationAccess({ commit, dispatch }) {
		await dispatch('settings/save', { name: 'notifications', value: 'false'}, { root: true });
		commit('setNotificationsRequested', false);
	},

	async notificationsGranted({ state }) {
		const notificationRequest = await Notification.requestPermission();
		return state.notificationsRequested && (notificationRequest == 'granted' || Notification.permission === "granted");
	},

	async onMessageHandler({ commit, dispatch }, message) {

		let payload;
		if (_.get(message, 'data.payload')) {
			payload = JSON.parse(message.data.payload);
		}


		if (_.get(message, 'data.call', false) == "true") {
			
			const { body } = message.data;
			dispatch('calls/receive', payload, { root: true });
			commit('setMessageBody', body);

		}

		if (_.get(payload, 'cancelled', false)) {
			dispatch('calls/cancelled', null, { root: true });
			dispatch('alerts/info', strings.calls.cancelled, { root: true })
		}

		if (_.get(payload, 'declined', false)) {
			dispatch('calls/declined', null, { root: true });
		}
	},

	async onServiceWorkerMessageHandler({ dispatch }, event) {

		if (!event.data.action) {
			return
		}

		switch (event.data.action) {
			case 'redirect-from-notificationclick':
				await dispatch('onMessageHandler', _.get(event, 'data'))
				break
		}

		//post message back to worker
		event.source.postMessage('recieved')
	},

	async batchLocalNotifications(ctx, notifications = []) {
		await LocalNotifications.schedule({
			notifications
		});
	}
};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};