import camelCase from 'lodash/camelCase';
import * as LDClient from 'launchdarkly-js-client-sdk';
import env from "@/api/env";

const clientSideIDs = {
	'dev': '6410f35bc354a812caf6dac9',
	'staging': '641207c1fa482313caa4bd8e',
	'production': '641207ee5a7cac140ed08778'
}

const formatFlags = (flags) => Object.fromEntries(
  Object.keys(flags).map((key) => [camelCase(key), flags[key]])
);

const rethrow = (e) => {
// Intended to be mocked in tests to avoid uncaught promise rejections in dependencies
  throw e;
};

export default {
  async install(vue, options = {}) {
    let {
      clientSideId,
      user,
      options: ldOptions,
      flagsStub,
      readyBeforeIdentify = true,
    } = options;

    if(!clientSideId) {
      clientSideId = clientSideIDs[env.name()]
    }

    let $ld;

    if (flagsStub) {
      $ld = this.stub({ flagsStub, readyBeforeIdentify });
    } else {
      $ld = this.initialize({ clientSideId, user, ldOptions, readyBeforeIdentify });
    }

    vue.prototype.$ld = $ld;
  },
  initialize  ({ clientSideId, user, ldOptions, readyBeforeIdentify }) {
    try {
      user || (user = { key: clientSideId });
      const ldClient = LDClient.initialize(clientSideId, user, ldOptions);
      const $ld = {
        ldClient,
        identify({ newUser, hash, callback }) {
          return new Promise((resolve) => {
            this.ready = false;
            ldClient.identify(newUser, hash, callback).then(() => {
              this.ready = true;
              resolve();
            });
          });
        },
        flags: {},
        ready: false,
        error: null,
      };

      ldClient.on('ready', () => {
        $ld.flags = formatFlags(ldClient.allFlags());
        $ld.ready = readyBeforeIdentify;
      });

      ldClient.on('change', (changes) => {
        const flattenedFlags = Object.fromEntries(
          Object.keys(changes).map((key) => [key, changes[key].current])
        );

        $ld.flags = {
          ...$ld.flags,
          ...formatFlags(flattenedFlags),
        };
      });

      ldClient.on('error', (e) => {
        $ld.error = e;
        rethrow(e);
      });

      return $ld;
    } catch(e) {
      console.log('error initializing launch darkly ', e)
    }
    
  },
  stub ({ flagsStub, readyBeforeIdentify }) {
    return {
      identify() {
        this.ready = true;
      },
      flags: flagsStub,
      ready: readyBeforeIdentify,
    }
  }
}