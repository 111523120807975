import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import Vue from 'vue'
import store from "@/store/index";
import env from "@/api/env";
import { Device } from '@capacitor/device';
import { getWebVersion } from "@/utils/version";


let version;

export default Bugsnag.start({
  apiKey: "ad9c935355e0a218808ea33823a23f8c",
  plugins: [new BugsnagPluginVue()],
  autoNotify: true,
  releaseStage: env.name() || 'production',

  onError: async event => {
    let community = null;
    let user = null;
    let fullName = null;
    let email = null
    try {
      community = store.getters["user/communityName"];
      user = store.getters["user/id"];
      fullName = store.getters["user/fullName"];
      email = store.getters["user/email"];
    } catch (e) {
      // Don't throw no matter what!
    }

    try {
      const info = await Device.getInfo();
      event.device = info;

    } catch (e) {
      // Don't throw no matter what!
    }

	try {
		if(!version)
		{
			//get version once
			version = await getWebVersion();
		}

		event.app.version = version;
  
	} catch (e) {
		// Don't throw no matter what!
	}
    

    // Attach user selected community/system information to report
    event.addMetadata("user", {
      context: {
        community,
      },
    });
    event.setUser(user, email, fullName)


    return event;
  }
});

const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)
