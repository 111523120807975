import papi from "@/api/papi";
import { set } from "@/utils/vuex";

const state = () => ({
	items: [],
	loaded: false,
});

const getters = {
	items: (state, g, rs, rootGetters) =>
		state.items.filter((shortcut) => {
			if (shortcut.type !== "section") return true;
			return rootGetters["user/system"];
		}),
	isEmpty: (state) => state.loaded && !state.items.length,
};

const mutations = {
	setItems: set("items"),
	setLoaded: set("loaded"),
};

const actions = {
	load: async ({ commit }) => {
		commit("setLoaded", false);

		const shortcuts = await papi.getShortcuts();

		commit("setItems", shortcuts || []);
		commit("setLoaded", true);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
