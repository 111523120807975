<template>
	<div>
		<v-avatar v-if="image && !isBaseProfileImage" :size="size" color="transparent" class="userAvatar">
			<v-img :src="image" :lazy-src="lazy" />
		</v-avatar>
		<v-avatar v-else :size="size" color="accent" :style="{ fontSize: font }">
			{{initials}}					
		</v-avatar>
	</div>	
</template>

<script>

import _ from 'lodash';

export default {
	name: "UserAvatar",
	props: {
		user: Object,
		size: {
			type: Number,
			default: 40
		}
	},
	computed: {
		lazy()
		{
			const { thumbnail, image, profileImage } = this.user;
			return thumbnail || image || profileImage;
		},
		image()
		{
			const { thumbnail, image, profileImage } = this.user;

			if(this.size > 100)
			{
				return image || profileImage;
			} else {
				return thumbnail || image || profileImage;
			}
			
		},
		isBaseProfileImage() {
			return this.$store.state.directory.baseProfileImage === this.user.profileImage;
		},
		initials()
		{
			const { name, fullName } = this.user;
			const text = name || fullName;
			
			if(text)
			{
				const parts = text.split(' ');
				const first = parts[0];
				const last = parts.pop();
				return `${_.upperCase(first)[0]}${_.upperCase(last)[0]}`;
			}

			return '';
		},
		font()
		{
			return `${this.size / 3}px`;
		}
	}
};
</script>

<style scoped>
.userAvatar {
	background-color: var(--v-navigation-darken3) !important;
}

.theme--dark .userAvatar {
	background-color: var(--v-navigation-lighten3) !important;
}
</style>
