import papi from "@/api/papi";
import auth from '@/api/auth';
import env from '@/api/env';
import firebase from '@/api/firebase';
import { set, get } from "@/utils/vuex";
import _ from 'lodash';

const state = () => ({
	current: null,
	system: null,
	homeControls: null,
	email: null,
	refreshTimeSeconds: 14400, // refresh jwt after four hours
	lastLogin: null,
	setBioDismissed: false,
	relationships: [
		{ text: 'Spouse', value: 'Spouse' },
		{ text: 'Sibling', value: 'Sibling' },
		{ text: 'Son', value: 'Son' },
		{ text: 'Daughter', value: 'Daughter' },
		{ text: 'Grandson', value: 'Grandson' },
		{ text: 'Granddaughter', value: 'Granddaughter' },
		{ text: 'Friend', value: 'Friend' },
		{ text: 'Other', value: 'Other' },
		{ text: 'In Laws', value: 'In_Laws' }
	],
	optOutOfFields: []
});


const getters = {
	signedIn(state) {
		return !!state.current;
	},
	current(state) {
		return state.current
	},
	lastLogin(state) {
		return state.lastLogin;
	},
	communityName(state) {
		const communities = _.get(state.current, "communities", []);
		const community = _.find(communities, [
			"_id",
			state.current?.community.communityId,
		]);
		return community.name;
	},
	isResident(state) {
		return _.get(state.current, "roles", []).indexOf("Resident") > -1;
	},

	isFamily(state) {
		return _.get(state.current, "roles", []).indexOf("Family") > -1;
	},

	isStaff(state) {
		return _.get(state.current, "roles", []).indexOf("Staff") > -1;
	},

	isAdvocate(state) {
		return _.get(state.current, "roles", []).indexOf("Advocate") > -1;
	},

	notStaff(state) {
		return _.get(state.current, "roles", []).indexOf("Staff") === -1;
	},

	hasRoles: (state) => (roles = []) => {
		return !_.chain(_.get(state.current, "roles", [])).intersection(roles).isEmpty().value();
	},

	audiences(state, getters) {
		return getters.isStaff
			? ["Resident", "Family"]
			: getters.isResident
			? "Resident"
			: "Family";
	},

	system: get("system"),
	homeControls: get("homeControls"),
	email: get("email"),
	id: get("current._id"),
	fullName: get("current.fullName"),
	profileImage: get("current.profileImage"),
	hasBio: (state) => !!get("current.biography")(state),
	roomName: get("current.rooms[0].name"),
	communityId: get("current.community.communityId"),

	alexaAddressBook: get("current.alexaAddressBook"),
	alexaMaxContactsReached: (s, getters) =>
		(getters.alexaAddressBook?.contacts?.reduce(
			(sum, contact) =>
				sum +
				(contact?.type === "pstn" || contact?.type === "reciprocal"
					? contact?.phoneNumbers?.length
					: 0 || 0),
			0
		) || 0) >= 10,
	isInAlexaPhoneBook: (s, getters) => (person) =>
		!!getters.alexaAddressBook?.contacts
			.flatMap((contact) => contact.phoneNumbers)
			.find((number) =>
				[person.phoneNumber, person.primaryPhone]
					.map((n) => n?.trim() || "")
					.includes(number.trim())
			),
	communitySettings: get("current.community.settings"),
	alexaCallingEnabled: get("current.community.settings.alexaCallingEnabled"),
	alexaWakeWord: get("current.community.settings.alexaWakeWord"),
	residentVideoCalling: get("current.community.settings.residentVideoCalling"),
	requestExperienceEnabled: get("current.community.settings.requestExperienceEnabled"),

	optOutOfFields: get("optOutOfFields"),
};

const mutations = {
	setCurrent: set("current"),
	setEmail: set("email"),
	setSystem: set("system"),
	setHomeControls: set("homeControls"),
	setLastLogin: set("lastLogin"),
	setBioDismissed: set("setBioDismissed"),
	setOptOutFields: set("optOutOfFields"),

	setBiography(state, biography)
	{
		if(state.current)
		{
			state.current.biography = biography;
		}
	},

	setOptOut(state, optOut)
	{
		if(state.current)
		{
			state.current.optOutOfDirectory = optOut;
		}
	},

	setAlexaAddressBook(state, addressBook) {
		if (state.current) {
			state.current.alexaAddressBook = addressBook;
		}
	},

	setCommunity(state, community) {
		if(state.current) {
			state.current.community = community;
		}
	}
};

const actions = {

	init: async ({commit, dispatch, rootGetters}) => {

		const newLogin = new Date();
		const stored =  rootGetters['settings/value']('lastLogin');
		const lastLogin = (stored) ? new Date(stored) : newLogin;

		await dispatch('settings/save', { name: 'lastLogin', value: newLogin.toISOString()}, { root: true });

		commit('setLastLogin', lastLogin);
		commit("setBioDismissed", !!localStorage.getItem("setBioDismissed"))
		
		
	},

	preload: async ({commit}) => {
		//will get current JWT or redirect to login

		const user = await papi.init();
		commit('setCurrent', user);


		return user;

	},

	load: async ({state, commit, getters, dispatch, rootGetters}) => {

		let user = state.current;

		if(!user)
		{
			user = await dispatch('preload');
		}
		
		const userDetails = await papi.getDetails(getters['id']);
		state.current = {...user, ...userDetails}

		console.log(userDetails);

		commit('setOptOutFields', userDetails.hiddenFields || []);

		if(getters['isResident']) {

			//TODO remote needs to support JWT to use Smarthome now 
			// TODO REPLACE THIS WITH ^ REMOTE JWT
			const credentials = await auth.getCredentials();

			if(credentials)
			{
				const system = await papi.getHomeControls(credentials);
				const deviceId = rootGetters['settings/value']('deviceId');

				if(system && system.systemStatus === 'Deployed' && deviceId)
				{
					const controls = {
						username: user._id,
						system: system._id,
						analytics: system.analyticsId,
						ctoken: system.ctoken,
						password: system.password,
						from: deviceId
					};

					commit('setSystem', system._id);
					commit('setHomeControls', controls);
				}
			}
		}



		setTimeout(async ()=>{

			try {
				await dispatch('preload');
			} catch(e) {
				//refresh failed, redirect to login
				location.href = env.login();
			}

		}, state.refreshTimeSeconds * 1000);

		
	},

	logout: async ({rootGetters}) => {

		const isK4App = rootGetters['mobile/isK4App'];
		if(!isK4App) {
			await firebase.deleteFirebaseToken();
		}

		localStorage.removeItem('vuex');
		
		await auth.logout();
	},


	share: async (ctx, { first, last, email, relationship, phone }) => {

		return papi.share(first, last, email, relationship, phone);

	},

	dismissSetBio() {
		localStorage.setItem("setBioDismissed", true);
	},

	biography: async ({commit}, biography) => {

		await papi.updateProfile(biography);
		commit('setBiography', biography);
	},

	optOutOfDirectory: async ({ commit }, optOut) => {
		await papi.updateOptOutSettings(optOut);
		commit("setOptOut", optOut);
	},

	optOutOfField: async ({ commit, state }, { field, optOut }) => {

		let fields = [ ...state.optOutOfFields ];

		if(optOut)
		{
			if(fields.indexOf(field) === -1)
			{
				fields.push(field);
			}

			await papi.updateOptOutFields(fields);
		} else {
			fields = _.without(fields, field);

			await papi.updateExposeFields([field]);
		}
		
		
		
		commit("setOptOutFields", fields);
	},

	refreshAlexaContactList: async ({ commit }) => {
		const addressBook = await papi.alexaAddressBook();
		commit("setAlexaAddressBook", addressBook);
	},

	addAlexaContact: async ({ getters }, contact) => {
		const payload = {
			contactName: contact.fullName,
			phoneNumber: contact.primaryPhone,
			roomName: getters["roomName"],
		};
		return papi.addAlexaContact(payload);
	},

	removeAlexaContact: async ({ getters }, contact) => {
		const payload = {
			contactName: contact.fullName,
			roomName: getters["roomName"],
		};
		return papi.removeAlexaContact(payload);
	},

	async setCommunity({state, commit, dispatch}, community) {

		if(state.current && community) {
			await dispatch('settings/save', { name: 'community', value: JSON.stringify(community)}, { root: true });
			await dispatch('settings/remove', 'theme', { root: true });
			await dispatch('settings/remove', 'desktopHome', { root: true });
			commit("setCommunity", community);
			localStorage.removeItem('vuex');
			window.location.reload();
		}
	}

};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};

