import papi from "@/api/papi";
import {set} from "@/utils/vuex";
import moment from "moment";
import _ from 'lodash';

const state = () => ({
	restaurants: [],
	menus: [],
	restaurant: null,
	menu: null,
	menuId: null,
	date: null,
	cacheTime: null
});


const getters = {

	loaded(state)
	{
		const EXPIRE = 7200000; //two hours
		return state.cacheTime && state.cacheTime + EXPIRE > Date.now();
	},

	menuTitle(state)
	{
		if(state.menu)
		{
			return state.menu.name;
		}
		
	},
	restaurantTitle(state)
	{
		if(state.restaurant)
		{
			const restaurant = _.find(state.restaurants, [ '_id', state.restaurant]);
			return restaurant.name;
		}
		
	},
	isEmpty(state, getters)
	{
		//check menus for empty
		if(getters.loaded && state.menus.length === 0)
		{
			return true;
		}

		//check for not loaded or no selected menu
		if(!getters.loaded || !state.menu)
		{
			return false;
		}

		//check menu categories for empty
		let found = _.find(state.menu.sections, (section) => {
			return section.availableMenuItemsByCategory.length > 0;
		});
		
		return !found;
	}
};

const mutations = {
	setRestaurants: set('restaurants'),
	setRestaurant(state, restaurant)
	{
		state.restaurant = restaurant;
		state.menus = [];
		state.menu = null;
		state.cacheTime = null;
	},
	setMenus(state, menus)
	{
		state.menus = menus;
		state.menu = null;
		state.cacheTime = (menus.length === 0) ? Date.now() : null; //nothing more to do if no menus
	},
	setMenu: set('menu'),
	setMenuId: set('menuId'),
	setDate: set('date'),
	setLoaded(state, value)
	{
		if(value)
		{
			state.cacheTime = Date.now();
		} else {
			state.cacheTime = null;
		}
	}
};

const actions = {
	load: async ({commit}) => {
		const restaurants = await papi.getRestaurants();
		commit('setRestaurants', restaurants);
	},

	setRestaurant: async ({state, commit, dispatch}, restaurantId) => {

		if(state.restaurants.length === 0)
		{
			await dispatch('load');
		}
		
		commit('setRestaurant', restaurantId);

		const menus = await papi.getMenus(restaurantId);
		commit('setMenus', menus);
	},

	setMenu: async ({commit, state, dispatch}, { restaurantId, menuId}) => {

		if(state.restaurant !== restaurantId)
		{
			await dispatch('setRestaurant', restaurantId);
		}

		commit('setMenuId', menuId)

		const date = new Date();

		commit('setDate', date.toISOString())

		await dispatch('loadMenu');
		
	},

	loadMenu: async ({commit, state}) => {

		const { restaurant, menuId, date} = state;

		const menu = await papi.getMenu(restaurant, menuId, date);
		commit('setMenu', menu);
		commit('setLoaded', true);

	},

	next({commit, dispatch, state})
	{
		let date = moment(state.date).add(1, 'day');

		const { startDate, endDate } = state.menu.availability;

		if(date.isBetween(startDate, endDate))
		{
			commit('setDate', date.toISOString());
			dispatch('loadMenu');
		}

	},

	prev({commit, dispatch, state})
	{
		let date = moment(state.date).subtract(1, 'day');

		const { startDate, endDate } = state.menu.availability;

		if(date.isBetween(startDate, endDate))
		{
			commit('setDate', date.toISOString());
			dispatch('loadMenu');
		}

	}

	
};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};