import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import axios from 'axios';


export const getAppVersion = async () => {

	if (Capacitor.isNativePlatform()) {
		const appInfo = await App.getInfo();
		return `${appInfo.version} (${appInfo.build})`;
	}

};

export const getWebVersion = async () => {
	
	try {
		const { data } = await axios.get('/version.json');
		return data.version;
	} catch(e) {
		//fail to load, will happen on local dev without building
	}
};
