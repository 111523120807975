<template>
	<component :is="component"/>
</template>

<script>
export default {
	name: 'MenuComponent',
	props: {
		name: String
	},
	computed: {
		component()
		{
			return () =>  import(`@/components/navigation/menuComponents/${this.name}`)
		}
	}

}
</script>