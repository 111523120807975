<template>
	<div class="text-center">
		<v-overlay :opacity="0.0">
			<v-progress-circular
				indeterminate
				:size="size"
			></v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>

export default {
	name: "LoadingOverlay",
	data: () => ({
		size: 32,
	}),
};
</script>