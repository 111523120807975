import { Capacitor } from '@capacitor/core';
import store from '@/store/index';


export default {

	api()
	{
		return this.url('api', '/v3/graph/graphql');	
		
	},

	auth()
	{
		return this.url('api', '/v3/auth');	
	},

	sso()
	{
		return this.url('api', '/v2/sso');		
	},

	content()
	{
		return this.url('api', '/v2/content');		
	},

	remote()
	{
		return this.url('remote', '');
	},

	login()
	{
		const dark = store.getters['theme/dark'];
		const suffix = this.suffix();

		const base = process.env.VUE_APP_LOCAL_LOGIN || `https://login${suffix}.k4connect.com`;
		
		return `${base}/login?theme=default&dark=${dark}&redirect=${location.href}`;
	},

	logout()
	{
		const dark = store.getters['theme/dark'];
		const suffix = this.suffix();

		const base = process.env.VUE_APP_LOCAL_LOGIN || `https://login${suffix}.k4connect.com`;
	
		//send origin for redirect, not /logout
		return `${base}/logout?theme=default&dark=${dark}&redirect=${location.origin}&force=true`;
	},

	url(service, path)
	{
		const suffix = this.suffix();
		return `https://${service}${suffix}.k4connect.com${path}`;
	},


	suffix()
	{
		const name = this.name();
		return (name === 'production') ? '' : `-${name}`;

	},

	name()
	{
		const { href } = location;

		const web = href.indexOf('k4community') >  -1;

		if(!web && process.env.VUE_APP_BUILD_TARGET)
		{
			return process.env.VUE_APP_BUILD_TARGET;
		}

		//use dev api for domains with -dev, any ip address, or localhost in web browser
		if(href.indexOf('dev') >  -1 || this.isIpAddress() || (!Capacitor.isNativePlatform() && href.indexOf('localhost') >  -1))
		{
			return 'dev';
		} else if(href.indexOf('staging') >  -1)
		{
			return 'staging';
		} else {
			return 'production';
		}
	},

	isIpAddress()
	{
		return (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(location.hostname));
	},

	firebaseConfig() {

		let config = {
			apiKey: 'AIzaSyA-XQJF_V7kVs9grDvfRFnFfl3Aokxg06Q',
			authDomain: 'k4community20.firebaseapp.com',
			databaseURL: 'https://k4community20.firebaseio.com',
			projectId: 'k4community20',
			storageBucket: 'k4community20.appspot.com',
			messagingSenderId: '490672454147',
			appId: '1:490672454147:web:d82cd705830b55dff52c36',
			measurementId: 'G-LZBV586MBH'
		}

		return config;

	},

	streamKey() {
		const name = this.name();

		let key;

		switch (name) {
			case 'dev':
				key = 'vgs4kthdqmx7'
				break;
			case 'staging':
				key = 'rtjjvj734nzc'
				break;
			case 'production':
				key = '5weht2wgnzxb'
				break;
			default:
				throw new Error('Unknown environment');
		}

		return key;
	}

	
}