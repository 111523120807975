import { strings } from '@/plugins/strings';
import { get } from "lodash"
import { set } from '@/utils/vuex';

const connectionErrors = {
	slow: {
		text: strings.errors.internet.slow,
		color: 'info',
	},
	offline: {
		text: strings.errors.internet.offline,
		color: 'error',
	}
}

const state = () => ({
	current: null,
	color: null,
	timeoutId: null,
	persistent: false
});


const getters = {

};

const mutations = {
	setCurrent: set("current"),
	setColor: set("color"),
	setTimeout: set("timeoutId"),
	setPersistent: set("persistent"),
	clearTimeout(state) {
		clearTimeout(state.timeoutId);
		state.timeoutId = null;
	},
	clear(state) {
		clearTimeout(state.timeoutId);
		state.current = null;
		state.persistent = false;
	},
};

const actions = {
	init() {
		sessionStorage.removeItem("slowConnectionShown");
	},

	setAlert({ state, commit, dispatch, rootGetters }, { color, text }) {
		if (state.persistent) return;
		if (rootGetters['navigation/isOffline']) {
			return dispatch('connection', 'offline');
		}

		commit("clearTimeout")
		commit("setColor", color);
		commit("setCurrent", parseText(text));

		const id = setTimeout(() => {
			commit("clear");
		}, 5000);

		commit("setTimeout", id);
	},

	error({dispatch}, text)
	{
		dispatch('setAlert', {text: parseText(text), color: 'error'});
	},

	success({dispatch}, text)
	{
		dispatch('setAlert', {text: parseText(text), color: 'success'});
	},

	info({dispatch}, text)
	{
		dispatch('setAlert', {text: parseText(text), color: 'info'});
	},

	warning({dispatch}, text)
	{
		dispatch('setAlert', {text: parseText(text), color: 'warning'});
	},

	connection({ commit }, type) {

		if (type === 'slow') {
			// only show once per session
			if (sessionStorage.getItem('slowConnectionShown')) {
				return;
			}
			sessionStorage.setItem('slowConnectionShown', true);
		}

		const { color, text } = connectionErrors[type];

		commit('setColor', color);
		commit('setCurrent', text);
		commit('setPersistent', true);
	},

	dismissAlert({ commit }) {
		commit('clear');
	}
};

const parseText = (text) => {
	if (typeof text !== 'string' || !text.startsWith("$")) {
		return text;
	}

	const path = text.slice(1);

	return get(strings, path, text);
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
