<template>
  <v-banner v-if="loaded && notificationsRequested === false" single-line color="info" class="notificationBanner">
      Click 'Ok' to enable calls and notifications.
      <template v-slot:actions>
      <v-btn @click="denyWebNotificationAccess">
        Dismiss
      </v-btn>
      <v-btn @click="grantWebNotificationAccess">
        Ok
      </v-btn>
    </template>
      
  </v-banner>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {

	name: 'NotificationBanner',

	computed: {
		...mapState('notifications', ['notificationsRequested']),
		...mapGetters('notifications', ['loaded']),
	},

  methods: {
    async grantWebNotificationAccess()
    {
      await this.$store.dispatch('notifications/grantWebNotificationAccess')
    },
    async denyWebNotificationAccess()
    {
      await this.$store.dispatch('notifications/denyWebNotificationAccess')
    }
  },

	data: () => ({
	}),
}
</script>

<style>

.notificationBanner {
	z-index: 100;
  position: sticky;
  top: 0;
}

</style>
